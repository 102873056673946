import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useRef } from 'react';
import { HP_TYPE, NEED } from '../../../../../../../../server/constants';
import {
  HPG_P_COLD,
  HPG_P_HEAT,
  HP_HEAT_TRANSFER_FLUID
} from '../../../../../../../../server/models/config/thermalProduction/heatpumps/settings.model';
import FormInput from '../../../../../../components/Form/FormInput';
import FormSelect from '../../../../../../components/Form/FormSelect';
import Section from '../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';

const HpgSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [refs]
  const nbErrors = useRef(0);
  //#endregion

  //#region [effects]
  useEffect(() => {
    return () => {
      if (nbErrors.current !== 0) removeErrors(nbErrors.current);
    };
  }, []);
  //#endregion

  //#region [methods]
  const handleAddError = () => {
    nbErrors.current++;
    addError();
  };

  const handleRemoveError = () => {
    nbErrors.current--;
    removeErrors(1);
  };

  const handleHPGChange = (param, value) => {
    config.ConfigsSst[0].Data.heatpumps.settings[HP_TYPE.GEO][param.key] =
      value;
    onFormChange(config);
  };

  const handleFluidChange = (value) => {
    config.ConfigsSst[0].Data.heatpumps.settings.heatTransferFluid = value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { services, heatpumps } = config.ConfigsSst[0].Data;
  return (
    <Section title={i18n._('config.hps.settings.hpg.title')} level={2} open>
      <div className='config-form-row'>
        {(services.needs.includes(NEED.HEAT) ||
          services.needs.includes(NEED.DHW)) && (
          <FormInput
            value={heatpumps.settings[HP_TYPE.GEO].pHeat}
            param={HPG_P_HEAT.pHeat}
            type={INPUT_TYPE.NUMBER}
            onChange={(value) => handleHPGChange(HPG_P_HEAT.pHeat, value)}
            unit
            label={i18n._('config.hps.settings.pHeat')}
            addError={handleAddError}
            removeError={handleRemoveError}
            className='config-form-input'
          />
        )}
        {services.needs.includes(NEED.COLD) && (
          <FormInput
            value={heatpumps.settings[HP_TYPE.GEO].pCold}
            param={HPG_P_COLD.pCold}
            type={INPUT_TYPE.NUMBER}
            onChange={(value) => handleHPGChange(HPG_P_COLD.pCold, value)}
            unit
            label={i18n._('config.hps.settings.pCold')}
            addError={handleAddError}
            removeError={handleRemoveError}
            className='config-form-input'
          />
        )}
        <FormSelect
          label={i18n._('config.hps.settings.hpg.heatTransferFluid')}
          param={HP_HEAT_TRANSFER_FLUID.heatTransferFluid}
          value={heatpumps.settings.heatTransferFluid}
          onChange={(value) => handleFluidChange(value)}
          className='config-form-input'
          disabled={config.IsModular}
        />
      </div>
    </Section>
  );
  //#endregion
};

export default HpgSection;
