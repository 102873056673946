import { useLingui } from '@lingui/react';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  DESCRIPTION,
  MODULAR
} from '../../../../../../server/models/config/buildingDescription/general.model';
import { updateConfig } from '../../../../api/config.api';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import { FORM_STATUS, INPUT_TYPE } from '../../../../constants';
import ConfigsContext from '../../../../contexts/ConfigsContext';
import PopupContext from '../../../../contexts/PopupContext';
import { isNull } from '../../../../utils/data.utils';
import ConfigFormPage from '../../components/ConfigFormPage/ConfigFormPage';

const GeneralPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config, setConfig, refreshConfig } = useContext(ConfigsContext);
  const { openErrorToast, openConfirmModal } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [formStatus, setFormStatus] = useState(FORM_STATUS.ORIGIN);
  const [nbErrors, setNbErrors] = useState(0);
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region [methods]
  const addError = () => setNbErrors((prevErrors) => prevErrors + 1);

  const removeErrors = (value) =>
    setNbErrors((prevErrors) => prevErrors - value);

  const save = async () => {
    try {
      await updateConfig(config);
      await refreshConfig();
    } catch (err) {
      throw err;
    }
  };

  const handleParamChange = (param, value) => {
    setConfig((conf) => ({ ...conf, [param.key]: value }));
    setFormStatus(() => FORM_STATUS.DIRTY);
  };

  const handleSaveClick = async () => {
    try {
      setFormStatus(() => FORM_STATUS.SAVING);
      await save();
      setFormStatus(() => FORM_STATUS.SAVED);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
      setFormStatus(() => FORM_STATUS.DIRTY);
    }
  };
  //#endregion

  //#region [render]
  const { Name } = config;
  // Si une sous-station a été initiée avec des données, la config est vérouillée sur "modulaire" ou "non modulaire"
  const isSstEnabled = config.ConfigsSst.some(
    (configSst) => !isNull(configSst.Data)
  );
  return (
    <ConfigFormPage
      formStatus={formStatus}
      formErrors={nbErrors > 0}
      save={save}
    >
      <Bloc title={i18n._('config.general')}>
        <h2>{i18n._('config.general.description')}</h2>
        <FormInput
          label={i18n._('config.general.Name')}
          value={Name}
          param={DESCRIPTION.Name}
          type={INPUT_TYPE.TEXT}
          onChange={(value) => handleParamChange(DESCRIPTION.Name, value)}
          addError={addError}
          removeError={() => removeErrors(1)}
        />
      </Bloc>
      <Bloc>
        <h2>{i18n._('config.general.hydraulicDesign')}</h2>
        <Form.Check
          type='switch'
          label={i18n._('config.general.modularDesign')}
          checked={config.IsModular}
          onChange={(evt) =>
            handleParamChange(MODULAR.IsModular, evt.target.checked)
          }
          disabled={isSstEnabled}
        />
      </Bloc>
      <SaveButton
        disabled={nbErrors > 0}
        formStatus={formStatus}
        onClick={
          isSstEnabled
            ? () => handleSaveClick()
            : () =>
                openConfirmModal(
                  i18n._('config.general.modularModal.title'),
                  i18n._('config.general.modularModal.body'),
                  'warning',
                  () => handleSaveClick()
                )
        }
      />
    </ConfigFormPage>
  );
  //#endregion
};

export default GeneralPage;
