import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import {
  MODULE_PRIMARY_HE,
  MODULE_SECONDARY_HE,
  NEED
} from '../../../../../../../../server/constants';
import { getColdParams } from '../../../../../../../../server/models/config/buildingDescription/services/settings.model';
import FormInput from '../../../../../../components/Form/FormInput';
import Section from '../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';

const ColdSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [refs]
  const nbErrors = useRef(0);
  //#endregion

  //#region [effects]
  useEffect(() => {
    return () => {
      if (nbErrors.current !== 0) removeErrors(nbErrors.current);
    };
  }, []);
  //#endregion

  //#region [methods]
  const handleAddError = () => {
    nbErrors.current++;
    addError();
  };

  const handleRemoveError = () => {
    nbErrors.current--;
    removeErrors(1);
  };

  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.services[NEED.COLD][param.key] = value;
    onFormChange(config);
  };

  const handleExchangerChange = (evt) => {
    const { checked } = evt.target;
    const { services } = config.ConfigsSst[0].Data;
    const { primaryModule, secondaryModule } =
      services[NEED.COLD].hydraulicEquipment;
    services[NEED.COLD].exchanger = checked;
    // on ajoute ou enlève les équipements hydrauliques liés à l'échangeur
    if (services[NEED.COLD].hydraulicEquipment) {
      // module primaire
      [
        MODULE_PRIMARY_HE.PR211,
        MODULE_PRIMARY_HE.PR212,
        MODULE_PRIMARY_HE.PR213,
        MODULE_PRIMARY_HE.PR214
      ].forEach((equipement) => (primaryModule[equipement] = checked));
      // module secondaire
      secondaryModule[MODULE_SECONDARY_HE.PU200] = checked;
    }
    onFormChange(config);
  };

  const handleCombinedHotColdWaterTankChange = (evt) => {
    const { checked } = evt.target;
    const { services } = config.ConfigsSst[0].Data;
    services[NEED.COLD].combinedHotColdWaterTank = checked;
    if (services[NEED.HEAT]) {
      services[NEED.HEAT].combinedHotColdWaterTank = checked;
      if (checked) {
        services[NEED.HEAT].secondaryControl =
          services[NEED.COLD].secondaryControl;
      }
    }
    onFormChange(config);
  };

  const handleSecondaryControlChange = (evt) => {
    const { checked } = evt.target;
    const { services } = config.ConfigsSst[0].Data;
    services[NEED.COLD].secondaryControl = checked;
    if (services[NEED.COLD].combinedHotColdWaterTank && services[NEED.HEAT]) {
      services[NEED.HEAT].secondaryControl = checked;
    }
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const {
    pMax,
    temperature,
    temperatureBack,
    exchanger,
    combinedHotColdWaterTank,
    secondaryControl
  } = config.ConfigsSst[0].Data.services[NEED.COLD];
  const { heat } = config.ConfigsSst[0].Data.services;
  const COLD = getColdParams(heat ? heat.combinedHotColdWaterTank : false);
  return (
    <Section title={i18n._('need.cold')} level={2} open>
      <div className='config-form-row'>
        <FormInput
          value={pMax}
          param={COLD.pMax}
          type={INPUT_TYPE.NUMBER}
          onChange={(value) => handleParamChange(COLD.pMax, value)}
          unit
          label={i18n._('config.services.settings.pMax')}
          addError={handleAddError}
          removeError={handleRemoveError}
        />
        <div className='config-form-column'>
          <span className='form-input-label'>
            {i18n._('config.services.settings.tempDistribRegime')}
          </span>
          <div className='settings-distrib-inputs'>
            <FormInput
              value={temperature}
              param={COLD.temperature}
              type={INPUT_TYPE.NUMBER}
              onChange={(value) => handleParamChange(COLD.temperature, value)}
              unit
              className='form-input-small'
              addError={handleAddError}
              removeError={handleRemoveError}
            />
            <FormInput
              value={temperatureBack}
              param={COLD.temperatureBack}
              type={INPUT_TYPE.NUMBER}
              onChange={(value) =>
                handleParamChange(COLD.temperatureBack, value)
              }
              unit
              className='form-input-small'
              addError={handleAddError}
              removeError={handleRemoveError}
            />
          </div>
          <span className='form-input-small-text'>
            {i18n._('config.services.settings.defaultTemperatures', {
              temp: COLD.temperature.default,
              tempBack: COLD.temperatureBack.default
            })}
          </span>
        </div>
        <div className='config-form-column'>
          <Form.Check
            type='switch'
            label={i18n._('config.services.settings.coldExchanger')}
            checked={exchanger}
            onChange={handleExchangerChange}
          />
          <Form.Check
            type='switch'
            label={i18n._('config.services.settings.combinedHotColdWaterTank')}
            checked={combinedHotColdWaterTank}
            onChange={handleCombinedHotColdWaterTankChange}
          />
          <Form.Check
            type='switch'
            label={i18n._('config.services.settings.secondaryControl')}
            checked={secondaryControl}
            onChange={handleSecondaryControlChange}
          />
        </div>
      </div>
    </Section>
  );
  //#endregion
};

export default ColdSection;
