import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { updateGeostorageSettings } from '../../../../../api/configSst.api';
import Bloc from '../../../../../components/Bloc/Bloc';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import { FORM_STATUS } from '../../../../../constants';
import ConfigsContext from '../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../contexts/PopupContext';
import ConfigFormPage from '../../../components/ConfigFormPage/ConfigFormPage';
import CollectorsSection from './sections/CollectorsSection';
import HorizontalConnectionsSection from './sections/HorizontalConnectionsSection/HorizontalConnectionsSection';
import ProbesSection from './sections/ProbesSection/ProbesSection';
import SourceSection from './sections/SourceSection/SourceSection';

const SettingsPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config, setConfig, refreshConfig, modules } =
    useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [formStatus, setFormStatus] = useState(FORM_STATUS.ORIGIN);
  const [nbErrors, setNbErrors] = useState(0);
  //#endregion

  //#region [methods]
  const addError = () => setNbErrors((prevErrors) => prevErrors + 1);

  const removeErrors = (value) =>
    setNbErrors((prevErrors) => prevErrors - value);

  const save = async () => {
    try {
      await updateGeostorageSettings(
        config.ConfigsSst[0],
        config.IsModular,
        modules
      );
      await refreshConfig();
    } catch (err) {
      throw err;
    }
  };

  const handleFormChange = (conf) => {
    setConfig(() => ({ ...conf }));
    setFormStatus(() => FORM_STATUS.DIRTY);
  };

  const handleSaveClick = async () => {
    try {
      setFormStatus(() => FORM_STATUS.SAVING);
      await save();
      setFormStatus(() => FORM_STATUS.SAVED);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
      setFormStatus(() => FORM_STATUS.DIRTY);
    }
  };
  //#endregion

  //#region [render]
  const formErrors = nbErrors > 0;
  return (
    <ConfigFormPage formStatus={formStatus} formErrors={formErrors} save={save}>
      <Bloc title={i18n._('geostorage.settings.title')}>
        <SourceSection onFormChange={handleFormChange} />
        <ProbesSection
          onFormChange={handleFormChange}
          addError={addError}
          removeErrors={removeErrors}
        />
        <HorizontalConnectionsSection
          onFormChange={handleFormChange}
          addError={addError}
          removeErrors={removeErrors}
        />
        <CollectorsSection
          onFormChange={handleFormChange}
          addError={addError}
          removeErrors={removeErrors}
        />
      </Bloc>
      <SaveButton
        disabled={formErrors}
        formStatus={formStatus}
        onClick={handleSaveClick}
      />
    </ConfigFormPage>
  );
  //#endregion
};

export default SettingsPage;
