const {
  BTES,
  SOIL,
  getSoilTempParams
} = require('../models/design/geology.model');

const soilTempParams = getSoilTempParams();
const GEOLOGY_SCHEMA = {
  type: 'object',
  properties: {
    [soilTempParams.InitTrtChecked.key]: {
      type: soilTempParams.InitTrtChecked.type
    },
    [soilTempParams.InitSoilTemperature10m.key]: {
      type: soilTempParams.InitSoilTemperature10m.type
    },
    [soilTempParams.InitSoilTemperatureMean.key]: {
      type: soilTempParams.InitSoilTemperatureMean.type
    },
    [soilTempParams.InitTrtProbeDepth.key]: {
      type: soilTempParams.InitTrtProbeDepth.type
    },
    [SOIL.InitSoilThermalConductivity.key]: {
      type: SOIL.InitSoilThermalConductivity.type,
      minimum: SOIL.InitSoilThermalConductivity.min,
      maximum: SOIL.InitSoilThermalConductivity.max
    },
    [SOIL.InitSoilVolumeHeatCapacity.key]: {
      type: SOIL.InitSoilVolumeHeatCapacity.type,
      minimum: SOIL.InitSoilVolumeHeatCapacity.min,
      maximum: SOIL.InitSoilVolumeHeatCapacity.max
    },
    [BTES.InitBtesTemperatureMin.key]: {
      type: BTES.InitBtesTemperatureMin.type,
      minimum: BTES.InitBtesTemperatureMin.min,
      maximum: BTES.InitBtesTemperatureMin.max
    },
    [BTES.InitBtesTemperatureMax.key]: {
      type: BTES.InitBtesTemperatureMax.type,
      minimum: BTES.InitBtesTemperatureMax.min,
      maximum: BTES.InitBtesTemperatureMax.max
    },
    [BTES.InitBtesTemperatureDelta.key]: {
      type: BTES.InitBtesTemperatureDelta.type,
      minimum: BTES.InitBtesTemperatureDelta.min
    },
    [BTES.YearsT2Final.key]: {
      type: BTES.YearsT2Final.type,
      minimum: BTES.YearsT2Final.min
    },
    [BTES.InitBtesMaxWidth.key]: {
      type: BTES.InitBtesMaxWidth.type,
      minimum: BTES.InitBtesMaxWidth.min,
      maximum: BTES.InitBtesMaxWidth.max
    },
    [BTES.InitBtesProbeResistance.key]: {
      type: BTES.InitBtesProbeResistance.type,
      minimum: BTES.InitBtesProbeResistance.min,
      maximum: BTES.InitBtesProbeResistance.max
    },
    [BTES.InitBtesProbeInternalDiameter.key]: {
      type: BTES.InitBtesProbeInternalDiameter.type,
      minimum: BTES.InitBtesProbeInternalDiameter.min,
      maximum: BTES.InitBtesProbeInternalDiameter.max
    }
  },
  required: [
    soilTempParams.InitTrtChecked.key,
    soilTempParams.InitSoilTemperature10m.key,
    soilTempParams.InitSoilTemperatureMean.key,
    soilTempParams.InitTrtProbeDepth.key,
    SOIL.InitSoilThermalConductivity.key,
    SOIL.InitSoilVolumeHeatCapacity.key,
    BTES.InitBtesTemperatureMin.key,
    BTES.InitBtesTemperatureMax.key,
    BTES.InitBtesTemperatureDelta.key,
    BTES.YearsT2Final.key,
    BTES.InitBtesMaxWidth.key,
    BTES.InitBtesProbeResistance.key,
    BTES.InitBtesProbeInternalDiameter.key
  ],
  allOf: [
    {
      if: {
        properties: { [soilTempParams.InitTrtChecked.key]: { const: true } }
      },
      then: {
        properties: {
          [soilTempParams.InitSoilTemperature10m.key]: {
            const: soilTempParams.InitSoilTemperature10m.default
          },
          [soilTempParams.InitSoilTemperatureMean.key]: {
            type: soilTempParams.InitSoilTemperatureMean.type,
            exclusiveMinimum: 0
          },
          [soilTempParams.InitTrtProbeDepth.key]: {
            type: soilTempParams.InitTrtProbeDepth.type,
            exclusiveMinimum: 0,
            maximum: soilTempParams.InitTrtProbeDepth.max
          }
        }
      }
    },
    {
      if: {
        properties: { [soilTempParams.InitTrtChecked.key]: { const: false } }
      },
      then: {
        properties: {
          [soilTempParams.InitSoilTemperature10m.key]: {
            type: soilTempParams.InitSoilTemperature10m.type,
            minimum: soilTempParams.InitSoilTemperature10m.min,
            maximum: soilTempParams.InitSoilTemperature10m.max
          },
          [soilTempParams.InitSoilTemperatureMean.key]: {
            const: soilTempParams.InitSoilTemperatureMean.default
          },
          [soilTempParams.InitTrtProbeDepth.key]: {
            const: soilTempParams.InitTrtProbeDepth.default
          }
        }
      }
    }
  ],
  additionalProperties: false
};

module.exports = { GEOLOGY_SCHEMA };
