const {
  PIPE_MATERIAL,
  PIPE_NOMINAL_DIAMETER,
  PROBES_PER_CONNECTION,
  SRC_TYPE,
  UNIT,
  SRC_EXCHANGE
} = require('../../../../constants');
const { getDefaultValues } = require('../../../../utils/param.utils');

const SOURCE_EXCHANGE = Object.freeze({
  capture: {
    key: SRC_EXCHANGE.CAPTURE,
    default: true
  },
  injection: {
    key: SRC_EXCHANGE.INJECTION,
    default: true
  }
});

const SOURCE_TYPE = Object.freeze({
  type: {
    key: 'type',
    values: Object.values(SRC_TYPE),
    default: SRC_TYPE.BTES_GLYCOL_WATER,
    translateValuesIds: Object.values(SRC_TYPE).map(
      (source) => `geostorage.sourceType.${source}`
    ) // TODO côté front
  }
});

const PROBES = Object.freeze({
  count: {
    key: 'count',
    type: 'number',
    default: null,
    required: true
  },
  depth: {
    key: 'depth',
    type: 'number',
    default: 50,
    required: true,
    unit: UNIT.METER,
    min: 50,
    max: 800
  }
});

const getProbesHdpeNdParam = (depth) => {
  return {
    hdpeNd: {
      key: 'hdpeNd',
      values: PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE],
      default: depth < 150 ? 32 : 40
    }
  };
};

const HORIZONTAL_CONNECTIONS = Object.freeze({
  probesPerConnection: {
    key: 'probesPerConnection',
    default: 1,
    values: PROBES_PER_CONNECTION
  },
  fluidPathDistance: {
    key: 'fluidPathDistance',
    type: 'number',
    default: null,
    required: true,
    unit: UNIT.METER,
    min: 0
  }
});

const getConnectionsHdpeNdParam = (probesHdpeNd, probesPerConnection) => {
  const index = PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE].findIndex(
    (nd) => nd === probesHdpeNd
  );
  return {
    hdpeNd: {
      key: 'hdpeNd',
      values: PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE],
      default:
        probesPerConnection === 1
          ? PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE][index + 1]
          : PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE][index + 2]
    }
  };
};

const COLLECTORS = Object.freeze({
  hdpeNd: {
    key: 'hdpeNd',
    values: PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE],
    default: 32,
    unit: UNIT.MILLIMETER
  },
  fluidPathDistance: {
    key: 'fluidPathDistance',
    type: 'number',
    default: null,
    required: true,
    unit: UNIT.METER,
    min: 0
  }
});

const getDefaultGeostorageSettingsValues = () => {
  const PROBES_HDPE = getProbesHdpeNdParam(PROBES.depth.default);
  const CONNECTIONS_HDPE = getConnectionsHdpeNdParam(
    PROBES_HDPE.hdpeNd.default,
    HORIZONTAL_CONNECTIONS.probesPerConnection.default
  );
  return {
    source: {
      ...getDefaultValues(SOURCE_EXCHANGE),
      ...getDefaultValues(SOURCE_TYPE)
    },
    probes: {
      ...getDefaultValues(PROBES),
      ...getDefaultValues(PROBES_HDPE)
    },
    horizontalConnections: {
      ...getDefaultValues(HORIZONTAL_CONNECTIONS),
      ...getDefaultValues(CONNECTIONS_HDPE)
    },
    collectors: { ...getDefaultValues(COLLECTORS) }
  };
};

module.exports = {
  SOURCE_TYPE,
  PROBES,
  HORIZONTAL_CONNECTIONS,
  COLLECTORS,
  getProbesHdpeNdParam,
  getConnectionsHdpeNdParam,
  getDefaultGeostorageSettingsValues
};
