const { NEED, UNIT } = require('../../constants');

const DETAILED_RESULTS = Object.freeze({
  T1: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  T2: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauInBtes: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauOutBtes: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  Wpac: { unit: UNIT.KILOWATT, precision: 0 },
  Whpg: { unit: UNIT.KILOWATT, precision: 0 },
  QBtes: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 0 },
  TEauInSolar: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauOutSolar: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  OPEX: { unit: UNIT.EURO, precision: 0 },
  NetOPEX: { unit: UNIT.EURO, precision: 0 },
  QHeadLoss: { unit: UNIT.KILOWATT_HOUR_ELECTRIC, precision: 2 },
  CO2kg: { unit: UNIT.KILOGRAM, precision: 0 },
  CO2kgref: { unit: UNIT.KILOGRAM, precision: 0 },
  BtesFlowRate: { unit: UNIT.CUBIC_METER_PER_HOUR, precision: 0 },
  QItesCharge: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  WColdItes: { unit: UNIT.KILOWATT, precision: 0 },
  WItesHpa: { unit: UNIT.KILOWATT, precision: 0 },
  COPItesHpa: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  WColdHpa: { unit: UNIT.KILOWATT, precision: 0 },
  WHeatHpa: { unit: UNIT.KILOWATT, precision: 0 },
  WHotwHpa: { unit: UNIT.KILOWATT, precision: 0 },
  WGas: { unit: UNIT.KILOWATT, precision: 0 },
  QSolar: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  TDistrib: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TCondHt: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TCondHw: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  WInjectionHpa: { unit: UNIT.KILOWATT, precision: 0 },
  COPInjectionHpa: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  Qdemand: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  RevPV: { unit: UNIT.EURO, precision: 0 },
  PVProd: { unit: UNIT.KILOWATT_HOUR_ELECTRIC, precision: 0 },
  PVSoldToGrid: { unit: UNIT.KILOWATT, precision: 0 },
  PVNotUsedForThermalNeeds: { unit: UNIT.KILOWATT, precision: 0 },
  PVUnused: { unit: UNIT.KILOWATT_HOUR_ELECTRIC, precision: 0 },
  QGeocooling: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
});

// l'ordre est important, ne pas le modifier
const DETAILED_RESULTS_THERMAL_CAPACITY = Object.freeze({
  [NEED.HEAT]: {
    sources: {
      Q_Heat_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      QTotal_Heat: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  },
  [NEED.COLD]: {
    sources: {
      Q_Cold_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      QTotal_Cold: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  },
  [NEED.DHW]: {
    sources: {
      Q_Hotwater_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      QTotal_Hotwater: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  }
});

// l'ordre est important, ne pas le modifier
const DETAILED_RESULTS_CONSUMPTION = Object.freeze({
  [NEED.HEAT]: {
    sources: {
      W_Heat_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Heat_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Heat_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Heat_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Heat_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      Consumption_Heat: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  },
  [NEED.COLD]: {
    sources: {
      W_Cold_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Cold_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Cold_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Cold_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Cold_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      Consumption_Cold: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  },
  [NEED.DHW]: {
    sources: {
      W_Hotwater_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Hotwater_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Hotwater_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Hotwater_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      W_Hotwater_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      Consumption_Hotwater: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  }
});

module.exports = {
  DETAILED_RESULTS,
  DETAILED_RESULTS_THERMAL_CAPACITY,
  DETAILED_RESULTS_CONSUMPTION
};
