// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-input-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.form-input-small-text {
  font-size: 13px;
  color: var(--medium-grey);
  font-style: italic;
}

.form-input-top-text {
  margin: 0;
  padding: 0;
}

.form-input-label,
.form-input-small-text {
  white-space: pre-wrap;
  max-width: 500px;
}

.form-input-unit {
  font-size: 14px;
}

.form-input {
  width: 100%;
  max-width: 320px;
}

.form-input-small {
  width: 100%;
  max-width: 120px;
}

.form-input-error {
  border-color: var(--danger-color) !important;
}

.form-input-error:focus {
  border-color: var(--danger-color) !important;
  -webkit-box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;
          box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;
}

.form-input-changed {
  border: 1px solid rgba(15, 40, 132, 0.7) !important;
}

.form-input-info-tooltip.tooltip {
  max-width: 320px;
}

.form-input-info-tooltip .tooltip-inner {
  max-width: none;
  padding: 15px;
  text-align: left;
  white-space: pre-wrap;
}

.form-input-disabled {
  background-image: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Form/Form.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,QAAQ;EACR,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;;EAEE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,4CAA4C;EAC5C,iEAAiE;UACzD,yDAAyD;AACnE;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".form-input-label {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 8px;\n  font-size: 16px;\n  font-weight: 500;\n  margin-bottom: 5px;\n}\n\n.form-input-small-text {\n  font-size: 13px;\n  color: var(--medium-grey);\n  font-style: italic;\n}\n\n.form-input-top-text {\n  margin: 0;\n  padding: 0;\n}\n\n.form-input-label,\n.form-input-small-text {\n  white-space: pre-wrap;\n  max-width: 500px;\n}\n\n.form-input-unit {\n  font-size: 14px;\n}\n\n.form-input {\n  width: 100%;\n  max-width: 320px;\n}\n\n.form-input-small {\n  width: 100%;\n  max-width: 120px;\n}\n\n.form-input-error {\n  border-color: var(--danger-color) !important;\n}\n\n.form-input-error:focus {\n  border-color: var(--danger-color) !important;\n  -webkit-box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;\n          box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;\n}\n\n.form-input-changed {\n  border: 1px solid rgba(15, 40, 132, 0.7) !important;\n}\n\n.form-input-info-tooltip.tooltip {\n  max-width: 320px;\n}\n\n.form-input-info-tooltip .tooltip-inner {\n  max-width: none;\n  padding: 15px;\n  text-align: left;\n  white-space: pre-wrap;\n}\n\n.form-input-disabled {\n  background-image: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
