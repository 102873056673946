// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heating-plant-price-section {
    width: 600px;
}

.heating-plant-price-total {
    height: 45px;
    font-weight: bold;
}

.heating-plant-price-section-total {
    height: 40px;
    font-weight: bold;
    background-color: rgb(245, 245, 245);
}

.heating-plant-price-sub-total {
    height: 40px;
    font-weight: bold;
    background-color: white;
    padding-left: 18px !important;
}

.heating-plant-price-detail {
    height: 40px;
    background-color: white;
    padding-left: 31px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/deliverable/equipmentList/sections/heatingPlantPriceSection/HeatingPlantPriceSection.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,oCAAoC;AACxC;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,6BAA6B;AACjC","sourcesContent":[".heating-plant-price-section {\n    width: 600px;\n}\n\n.heating-plant-price-total {\n    height: 45px;\n    font-weight: bold;\n}\n\n.heating-plant-price-section-total {\n    height: 40px;\n    font-weight: bold;\n    background-color: rgb(245, 245, 245);\n}\n\n.heating-plant-price-sub-total {\n    height: 40px;\n    font-weight: bold;\n    background-color: white;\n    padding-left: 18px !important;\n}\n\n.heating-plant-price-detail {\n    height: 40px;\n    background-color: white;\n    padding-left: 31px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
