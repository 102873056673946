import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import { UNIT } from '../../../../../../../../server/constants';
import { formatValueWithUnit } from '../../../../../../utils/data.utils';

const EnergyCarbonGoalAlert = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const alert = useMemo(() => {
    const {
      InitConstraintEnergyFinale,
      InitConstraintEnergyPrimary,
      InitConstraintCO2kg,
      InitConstraintENR,
      InitConstraintHpg,
      InitConstraintGasPart
    } = result.ComputeResult.inp;

    const {
      EnergyFinalPercent,
      EnergyPrimaryPercent,
      CO2Percent,
      PartENR,
      PartHpgTfp,
      GasPart
    } = result.ComputeResult.summary;

    let variant = 'success';
    let text = '';

    if (InitConstraintEnergyFinale > 0) {
      const params = {
        result: formatValueWithUnit(i18n, -EnergyFinalPercent, UNIT.PERCENT),
        goal: formatValueWithUnit(
          i18n,
          InitConstraintEnergyFinale,
          UNIT.PERCENT
        )
      };
      if (-EnergyFinalPercent > InitConstraintEnergyFinale) {
        text = i18n._('results.energyAndCarbon.goal.energyFinal.sup', params);
      } else {
        variant = 'warning';
        text = i18n._('results.energyAndCarbon.goal.energyFinal.inf', params);
      }
    } else if (InitConstraintEnergyPrimary > 0) {
      const params = {
        result: formatValueWithUnit(i18n, -EnergyPrimaryPercent, UNIT.PERCENT),
        goal: formatValueWithUnit(
          i18n,
          InitConstraintEnergyPrimary,
          UNIT.PERCENT
        )
      };
      if (-EnergyPrimaryPercent > InitConstraintEnergyPrimary) {
        text = i18n._('results.energyAndCarbon.goal.energyPrimary.sup', params);
      } else {
        variant = 'warning';
        text = i18n._('results.energyAndCarbon.goal.energyPrimary.inf', params);
      }
    } else if (InitConstraintCO2kg > 0) {
      const params = {
        result: formatValueWithUnit(i18n, -CO2Percent, UNIT.PERCENT),
        goal: formatValueWithUnit(i18n, InitConstraintCO2kg, UNIT.PERCENT)
      };
      if (-CO2Percent > InitConstraintCO2kg) {
        text = i18n._('results.energyAndCarbon.goal.co2Emissions.sup', params);
      } else {
        variant = 'warning';
        text = i18n._('results.energyAndCarbon.goal.co2Emissions.inf', params);
      }
    } else if (InitConstraintENR > 0) {
      const params = {
        result: formatValueWithUnit(i18n, PartENR, UNIT.PERCENT),
        goal: formatValueWithUnit(i18n, InitConstraintENR, UNIT.PERCENT)
      };
      if (PartENR > InitConstraintENR) {
        text = i18n._('results.energyAndCarbon.goal.enrPart.sup', params);
      } else {
        variant = 'warning';
        text = i18n._('results.energyAndCarbon.goal.enrPart.inf', params);
      }
    } else if (InitConstraintHpg > 0) {
      const params = {
        result: formatValueWithUnit(i18n, PartHpgTfp, UNIT.PERCENT),
        goal: formatValueWithUnit(i18n, InitConstraintHpg, UNIT.PERCENT)
      };
      if (PartHpgTfp > InitConstraintHpg) {
        text = i18n._('results.energyAndCarbon.goal.hpgPart.sup', params);
      } else {
        variant = 'warning';
        text = i18n._('results.energyAndCarbon.goal.hpgPart.inf', params);
      }
    } else if (InitConstraintGasPart < 100) {
      const params = {
        result: formatValueWithUnit(i18n, GasPart, UNIT.PERCENT),
        goal: formatValueWithUnit(i18n, InitConstraintGasPart, UNIT.PERCENT)
      };
      if (GasPart > InitConstraintGasPart) {
        variant = 'warning';
        text = i18n._('results.energyAndCarbon.goal.gasPart.sup', params);
      } else {
        text = i18n._('results.energyAndCarbon.goal.gasPart.inf', params);
      }
    } else {
      variant = 'info';
      text = i18n._('results.energyAndCarbon.noGoal');
    }
    return { variant, text };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return (
    <Alert variant={alert.variant} className='mb-3'>
      {alert.text}
    </Alert>
  );
  //#endregion
};

export default EnergyCarbonGoalAlert;
