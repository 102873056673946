// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailed-btn-dl {
  font-size: 14px;
  margin-top: 10px;
}

.detailed-select-body {
  font-size: 14px;
  margin: 15px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.detailed-select {
  width: 120px;
  margin-left: 15px;
  font-size: 13px;
}

.detailed-instructions {
  font-style: italic;
  font-size: 13px;
}

.detailed-grid {
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
}

.detailed-header {
  font-weight: 300;
  font-size: 12px;
}

.detailed-header.selectable {
  cursor: pointer;
}

.detailed-cell {
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/simulationParams/components/DetailedResults/DetailedResults.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;AAC7B;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,wDAAwD;EACxD,gDAAgD;AAClD;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".detailed-btn-dl {\n  font-size: 14px;\n  margin-top: 10px;\n}\n\n.detailed-select-body {\n  font-size: 14px;\n  margin: 15px 0px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.detailed-select {\n  width: 120px;\n  margin-left: 15px;\n  font-size: 13px;\n}\n\n.detailed-instructions {\n  font-style: italic;\n  font-size: 13px;\n}\n\n.detailed-grid {\n  height: 400px;\n  border-radius: 5px;\n  overflow: hidden;\n  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n}\n\n.detailed-header {\n  font-weight: 300;\n  font-size: 12px;\n}\n\n.detailed-header.selectable {\n  cursor: pointer;\n}\n\n.detailed-cell {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
