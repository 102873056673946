import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { getDescriptionSchema } from '../../../../server/validation/description.validation';
import { GENERAL_SCHEMA } from '../../../../server/validation/general.validation';
import { fetchNewProject } from '../../api/company.api';
import { createProjectParent } from '../../api/project.api';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DescriptionsTabs from '../../containers/DescriptionsTabs/DescriptionsTabs';
import GeneralForm from '../../containers/GeneralForm/GeneralForm';
import AjvContext from '../../contexts/AjvContext';
import PopupContext from '../../contexts/PopupContext';
import { IS_DEV } from '../../utils/env.utils';
import {
  areSstsUniqueName,
  getStdFilesError
} from '../../utils/substation.utils';
import './NewProjectPage.css';

const NewProjectPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId } = useParams();
  //#endregion

  //#region [contexts]
  const { ajv } = useContext(AjvContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [companyName, setCompanyName] = useState();
  const [generalForm, setGeneralForm] = useState();
  const [imageFile, setImageFile] = useState();
  const [descsForms, setDescsForms] = useState();
  const [weatherForms, setWeatherForms] = useState([]);
  const [stdForms, setStdForms] = useState([]);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const newProject = await fetchNewProject(companyId);
        setCompanyName(() => newProject.CoName);
        setGeneralForm(() => newProject.general);
        setDescsForms(() => newProject.descriptions);
        setWeatherForms(() => newProject.weather);
        setStdForms(() => newProject.std);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [methods]
  const handleGeneralInputChange = (key, value) => {
    setGeneralForm((form) => ({ ...form, [key]: value }));
  };

  const handleGeneralImageChange = (file) => {
    setImageFile(file);
    setGeneralForm((oldForm) => ({
      ...oldForm,
      ImageFileID: file ? crypto.randomUUID() : null
    }));
  };

  const handleSubmitClick = async () => {
    try {
      const formData = new FormData();
      if (imageFile) {
        formData.append('imageFile', imageFile, imageFile.name);
      }
      weatherForms
        .filter((form) => form.file)
        .forEach((form) => {
          const encodedFilename = encodeURIComponent(form.file.name);
          const filename = `${form.fileId}_${encodedFilename}`;
          formData.append('weatherFiles', form.file, filename);
        });
      stdForms
        .filter((form) => form.file)
        .forEach((form) => {
          const encodedFilename = encodeURIComponent(form.file.name);
          const filename = `${form.fileId}_${encodedFilename}`;
          formData.append('stdFiles', form.file, filename);
        });
      const forms = descsForms.map((descForm) => ({
        ...generalForm,
        ...descForm
      }));
      formData.append('forms', JSON.stringify(forms));
      const newProjectParentId = await createProjectParent(formData);
      navigate(
        `/company/${companyId}/project/${newProjectParentId}/design/compute?type=opti`
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  if (!generalForm || !descsForms || !weatherForms || !stdForms) return null;

  // erreurs liées aux formulaires General
  const validateGeneralForm = ajv.compile(GENERAL_SCHEMA);
  const isGeneralFormValid = validateGeneralForm(generalForm);
  if (IS_DEV && validateGeneralForm.errors) {
    console.log(validateGeneralForm.errors);
  }
  return (
    <div className='new-project-page'>
      <Breadcrumb companyName={companyName} />
      {currentPageIndex === 0 && (
        <div>
          <GeneralForm
            form={generalForm}
            imageFile={imageFile}
            onInputChange={handleGeneralInputChange}
            onImageChange={handleGeneralImageChange}
          />
          <div className='bottom-page-btns'>
            <Button
              variant='primary'
              onClick={() => {
                setCurrentPageIndex(1);
                window.scrollTo(0, 0);
              }}
              disabled={!isGeneralFormValid}
              key='next-btn'
            >
              {i18n._('newProject.next')}
            </Button>
          </div>
        </div>
      )}
      {currentPageIndex === 1 &&
        (() => {
          // erreurs liées aux formulaires Descriptions
          const descsFormsErrors = descsForms
            .map((form) => {
              const schema = getDescriptionSchema(
                form.InitTemperatureHeat,
                form.InitTemperatureCold,
                form.InitWaterLawIsUsed
              );
              const validateDescForm = ajv.compile(schema);
              validateDescForm(form);
              if (IS_DEV && validateDescForm.errors) {
                console.log(validateDescForm.errors);
              }
              return validateDescForm.errors;
            })
            .filter((errors) => errors);

          // erreurs liées aux sous-stations
          const hasSstNameError = !areSstsUniqueName(descsForms[0].substations);

          // erreurs liées aux STD
          let stdFormsErrors = [];
          descsForms.forEach((form, formIndex) => {
            const descStdForms = stdForms.filter(
              (stdForm) =>
                stdForm.projectId === form.AhsID && stdForm.summaryData
            );
            const error = getStdFilesError(i18n, form, formIndex, descStdForms);
            if (error) stdFormsErrors.push(error);
          });

          const isDescFormValid =
            isGeneralFormValid &&
            !descsFormsErrors.length &&
            !hasSstNameError &&
            !stdFormsErrors.length;
          return (
            <div>
              <DescriptionsTabs
                forms={descsForms}
                weatherForms={weatherForms}
                stdForms={stdForms}
                onFormsChange={setDescsForms}
                onStdFormsChange={setStdForms}
                onWeatherFormsChange={setWeatherForms}
              />
              {descsFormsErrors.map((_, formIndex) => (
                <Alert
                  variant='danger'
                  key={'form_error_' + formIndex}
                  className='new-project-error'
                >
                  {i18n._('description.descriptionError', {
                    descIndex: formIndex + 1
                  })}
                </Alert>
              ))}
              {hasSstNameError && (
                <Alert variant='danger'>
                  {i18n._('description.uniqueSstNameError')}
                </Alert>
              )}
              {stdFormsErrors.map((error, stdFormIndex) => (
                <Alert
                  variant='danger'
                  key={'std_form_error_' + stdFormIndex}
                  className='new-project-error'
                >
                  {error}
                </Alert>
              ))}
              <div className='bottom-page-btns'>
                <Button
                  variant='primary'
                  onClick={() => {
                    setCurrentPageIndex(0);
                    window.scrollTo(0, 0);
                  }}
                  key='previous-btn'
                >
                  {i18n._('newProject.previous')}
                </Button>
                <Button
                  variant='primary'
                  onClick={handleSubmitClick}
                  disabled={!isDescFormValid}
                >
                  {i18n._('save')}
                </Button>
              </div>
            </div>
          );
        })()}
    </div>
  );
  //#endregion
};

export default NewProjectPage;
