// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-table th {
  color: white;
  padding: 5px;
  text-align: center;
}

.result-table-title {
  font-weight: 500;
  font-size: 17px;
  margin: 0;
}

.result-table-details {
  font-size: 14px;
  width: 100%;
  font-weight: 400;
  margin: 5px 0 0 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/TemplateTables/ResultsTable/ResultsTable.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".results-table th {\n  color: white;\n  padding: 5px;\n  text-align: center;\n}\n\n.result-table-title {\n  font-weight: 500;\n  font-size: 17px;\n  margin: 0;\n}\n\n.result-table-details {\n  font-size: 14px;\n  width: 100%;\n  font-weight: 400;\n  margin: 5px 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
