import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { PV_EQUIPMENT } from '../../../../../../../../../server/constants';
import { getParamsConstraints } from '../../../../../../../../../server/models/design/calculationData.model';
import { isCalculationDataParamPresent } from '../../../../../../../utils/calculationData.utils';
import KilowattCretePVModal from '../../../components/KilowattCretePVModal';
import SimuInput from '../../components/SimuInput';
import SimuSelect from '../../components/SimuSelect';
import SimuSstTable from './components/SimuSstTable/SimuSstTable';

const SimuSizingSection = ({
  project,
  calculationData,
  selectedProject,
  onConstraintChange,
  onSstConstraintChange,
  onBtesLengthValueChange,
  onSTSurfaceValueChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const constraints = useMemo(
    () => getParamsConstraints(selectedProject),
    [selectedProject]
  );
  //#endregion

  //#region [render]
  return (
    <div className='simulation-section'>
      <h2>{i18n._('compute.simu.sizing')}</h2>
      {isCalculationDataParamPresent(
        constraints.BtesLength.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.BtesLength')}
          value={calculationData.constraints.params.BtesLength}
          param={constraints.BtesLength}
          onChange={(_, value) => onBtesLengthValueChange(value)}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.BtesProbeDepth.key,
        calculationData
      ) && (
        <SimuSelect
          label={i18n._('compute.BtesProbeDepth')}
          value={calculationData.constraints.params.BtesProbeDepth}
          param={constraints.BtesProbeDepth}
          onChange={onConstraintChange}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.BtesProbeDistance.key,
        calculationData
      ) && (
        <SimuSelect
          label={i18n._('compute.BtesProbeDistance')}
          value={calculationData.constraints.params.BtesProbeDistance}
          param={constraints.BtesProbeDistance}
          onChange={onConstraintChange}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.T2final_Btes.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.T2final_Btes')}
          value={calculationData.constraints.params.T2final_Btes}
          param={constraints.T2final_Btes}
          onChange={onConstraintChange}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.HPGHeatBtesPowerMax.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.HPGHeatBtesPowerMax')}
          value={calculationData.constraints.params.HPGHeatBtesPowerMax}
          param={constraints.HPGHeatBtesPowerMax}
          onChange={onConstraintChange}
          maxFormula={i18n._('compute.HPGHeatBtesPowerMax.formula')}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.HPGColdBtesPowerMax.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.HPGColdBtesPowerMax')}
          value={calculationData.constraints.params.HPGColdBtesPowerMax}
          param={constraints.HPGColdBtesPowerMax}
          onChange={onConstraintChange}
          maxFormula={i18n._('compute.HPGColdBtesPowerMax.formula')}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.SolarThermalSurface.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.SolarThermalSurface')}
          value={calculationData.constraints.params.SolarThermalSurface}
          param={constraints.SolarThermalSurface}
          onChange={(_, value) => onSTSurfaceValueChange(value)}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.KiloWattCretePV.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.KiloWattCretePV')}
          value={calculationData.constraints.params.KiloWattCretePV}
          param={constraints.KiloWattCretePV}
          onChange={onConstraintChange}
          modalInfo={{
            title: i18n._('compute.KiloWattCretePV'),
            body: <KilowattCretePVModal />
          }}
          disabled={
            calculationData.constraints.params.SolarThermalSurface > 0 &&
            project.otherValues.InitPhotovoltaicModel ===
              PV_EQUIPMENT.DUALSUN.value
          }
        />
      )}
      {isCalculationDataParamPresent(
        constraints.QItesMax.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.QItesMax')}
          value={calculationData.constraints.params.QItesMax}
          param={constraints.QItesMax}
          onChange={onConstraintChange}
        />
      )}
      <SimuSstTable
        project={project}
        calculationData={calculationData}
        onSstConstraintChange={onSstConstraintChange}
      />
    </div>
  );
  //#endregion
};

export default SimuSizingSection;
