import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import PopupContext from '../../../../../contexts/PopupContext';
import { countElementOccurences } from '../../../../../utils/data.utils';
import './SubstationBloc.css';
import SubstationTab from './SubstationTab/SubstationTab';

const DEFAULT_ACTIVE_TAB = '0';
const SubstationBloc = ({
  form,
  formIndex,
  stdForms,
  onInputChange,
  onNewSstClick,
  onFileChange,
  onSstDelete
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openConfirmModal, openErrorToast, openToast } =
    useContext(PopupContext);
  //#endregion

  //#region [states]
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  //#endregion

  //#region [methods]
  const handleNewSstClick = () => {
    const sstFormsCount = form.substations.length;
    onNewSstClick();
    setActiveTab(() => '' + sstFormsCount);
  };

  const handleSstDelete = async (sstForm) => {
    try {
      await onSstDelete(sstForm);
      setActiveTab(() => DEFAULT_ACTIVE_TAB);
      openToast(
        i18n._('description.deleteSubstation.success.title'),
        i18n._('description.deleteSubstation.success.body'),
        'success'
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const isChild = formIndex > 0;
  return (
    <div className='description-file-bloc'>
      <h1>{i18n._('substationTabs.substations')}</h1>
      <div>
        {isChild && (
          <div className='substation-tabs-warning-body'>
            <FontAwesomeIcon icon='exclamation-circle' />
            {i18n._('substationTabs.warning')}
          </div>
        )}
        <Tab.Container
          defaultActiveKey={DEFAULT_ACTIVE_TAB}
          activeKey={activeTab}
          onSelect={setActiveTab}
        >
          <Nav variant='tabs'>
            {form.substations.map((sstForm, index) => (
              <Nav.Item key={'substation_' + index}>
                <Nav.Link eventKey={index}>
                  <span className='nav-link-span'>
                    <span onClick={() => setActiveTab('' + index)}>
                      {sstForm.InitStationName &&
                      sstForm.InitStationName.trim() !== ''
                        ? sstForm.InitStationName
                        : i18n._('substationTabs.InitStationName', {
                            index: index + 1
                          })}
                    </span>
                    {!isChild && index > 0 && (
                      <FontAwesomeIcon
                        icon='trash-alt'
                        onClick={() =>
                          openConfirmModal(
                            i18n._('description.deleteSubstation.title'),
                            i18n._('description.deleteSubstation.body'),
                            'danger',
                            async () => await handleSstDelete(sstForm)
                          )
                        }
                      />
                    )}
                  </span>
                </Nav.Link>
              </Nav.Item>
            ))}
            {!isChild && (
              <Nav.Item>
                <Nav.Link onClick={handleNewSstClick}>
                  <FontAwesomeIcon icon='plus-square' />
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Tab.Content>
            {form.substations.map((sstForm, sstIndex) => {
              const stdForm = stdForms.find(
                (form) => form.sstId === sstForm.InitStationID
              );
              const names = form.substations.map((sstForm) =>
                sstForm.InitStationName?.toLowerCase()
              );
              const duplicateError =
                names.every((name) => !!name?.trim().length) &&
                countElementOccurences(
                  names,
                  sstForm.InitStationName?.toLowerCase()
                ) > 1;
              return (
                <Tab.Pane
                  key={'substation_tab_' + sstIndex}
                  eventKey={sstIndex.toString()}
                >
                  <SubstationTab
                    sstForm={sstForm}
                    sstIndex={sstIndex}
                    formIndex={formIndex}
                    stdForm={stdForm}
                    duplicateSstNameError={duplicateError}
                    onInputChange={(key, value) =>
                      onInputChange(key, value, sstIndex)
                    }
                    onFileChange={(evt) => onFileChange(evt, sstIndex, stdForm)}
                  />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
  //#endregion
};

export default SubstationBloc;
