import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import NumberBadge from '../../../../../../../../components/NumberBadge/NumberBadge';
import PopupContext from '../../../../../../../../contexts/PopupContext';
import { formatValue, isNull } from '../../../../../../../../utils/data.utils';
import { getInputError } from '../../../../../../../../utils/form.utils';
import MaxModal from './MaxModal';

const MinMaxInputs = ({
  label,
  param,
  disabled,
  minValue,
  maxValue,
  modalInfo,
  maxFormula,
  showBadges,
  projectsUsingParam,
  onMinValueChange,
  onMaxValueChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const getEvtTargetValue = (evt) => {
    return !isNull(evt.target.value) && evt.target.value !== ''
      ? +evt.target.value
      : evt.target.value;
  };
  //#endregion

  //#region [render]
  const inputMinId = `input_min_${param.key}`;
  const inputMaxId = `input_max_${param.key}`;
  const badgesId = `badges_${param.key}`;
  const minValueError = getInputError(i18n, minValue, param);
  const maxValueError = getInputError(i18n, maxValue, param);
  let minLessMaxError = false;
  if (!minValueError && !maxValueError) {
    const isMinValueNull = isNull(minValue) || minValue === '';
    const isMaxValueNull = isNull(maxValue) || maxValue === '';
    minLessMaxError =
      minValue > maxValue ||
      (isMinValueNull && !isMaxValueNull) ||
      (!isMinValueNull && isMaxValueNull);
  }
  const minClassName =
    minValueError || minLessMaxError ? ' param-input-error' : '';
  const maxClassName =
    maxValueError || minLessMaxError ? ' param-input-error' : '';
  return (
    <Row>
      <Col>
        <span className='opti-inputs-label'>
          {label}
          {modalInfo && (
            <FontAwesomeIcon
              icon='circle-question'
              className='compute-info-icon modal-info-icon'
              onClick={() => openInfoModal(modalInfo.title, modalInfo.body)}
            />
          )}
        </span>
        {showBadges && (
          <span
            className='opti-inputs-badges'
            data-tooltip-content={i18n._('input.minMax.onlyForDescriptions', {
              constraint: i18n._(`constraint.${param.key}`),
              descriptions: projectsUsingParam
                .map((proj) => proj.description)
                .join('\n')
            })}
            data-tooltip-id={'info_' + badgesId}
          >
            {projectsUsingParam.map((desc, index) => (
              <NumberBadge
                number={desc.projectIndex}
                key={'min_max_inputs_desc_' + index}
              />
            ))}
            <Tooltip
              id={'info_' + badgesId}
              place='right'
              className='info-tooltip'
              opacity={1}
            />
          </span>
        )}
      </Col>
      <Col className='ps-0 pe-0'>
        <Row>
          <Col className='min-max-col'>
            <Form.Control
              className={`param-input${minClassName}`}
              type={param.type}
              disabled={disabled}
              onChange={(evt) =>
                onMinValueChange(param, getEvtTargetValue(evt))
              }
              value={minValue ?? ''}
              data-tooltip-content={minValueError}
              data-tooltip-id={'error_' + inputMinId}
              name={inputMinId}
            />
            {minValueError && (
              <Tooltip
                id={'error_' + inputMinId}
                place='bottom'
                className='error-tooltip'
                arrowColor='#f04460'
                opacity={1}
              />
            )}
          </Col>
          <Col className='min-max-col'>
            <InputGroup>
              <Form.Control
                className={`param-input${maxClassName}`}
                type={param.type}
                disabled={disabled}
                onChange={(evt) =>
                  onMaxValueChange(param, getEvtTargetValue(evt))
                }
                value={maxValue ?? ''}
                data-tooltip-content={maxValueError}
                data-tooltip-id={'error_' + inputMaxId}
                name={inputMaxId}
              />
              {maxValueError && (
                <Tooltip
                  id={'error_' + inputMaxId}
                  place='bottom'
                  className='error-tooltip'
                  arrowColor='#f04460'
                  opacity={1}
                />
              )}
              {maxFormula && (
                <InputGroup.Text
                  className='opti-input-max-icon'
                  data-tooltip-content={i18n._('input.minMax.max', {
                    max: formatValue(param.max, 0)
                  })}
                  data-tooltip-id={'info_' + inputMaxId}
                  onClick={() =>
                    openInfoModal(
                      i18n._('compute.maxParam.title', { param: label }),
                      <MaxModal
                        max={formatValue(param.max, 0)}
                        formula={maxFormula}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon icon='calculator' />
                  <Tooltip
                    id={'info_' + inputMaxId}
                    place='bottom'
                    className='info-tooltip'
                    opacity={1}
                  />
                </InputGroup.Text>
              )}
            </InputGroup>
          </Col>
        </Row>
        {minLessMaxError && (
          <Row>
            <Col xs={12}>
              <span className='minLessMax-error'>
                <FontAwesomeIcon icon='xmark' />
                {i18n._('input.error.minLessMax')}
              </span>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
  //#endregion
};

export default MinMaxInputs;
