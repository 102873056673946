// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-equipment-row {
    background-color: rgb(245, 245, 245);
    color: var(--dark-grey);
}

.sub-equipment-row th:nth-of-type(2), 
.sub-equipment-row td:nth-of-type(2) {
    padding-left: 24px;
}

.sub-equipment-row th {
    font-size: 14px;
    color: var(--medium-grey);
}

.sub-equipment-row th:nth-of-type(4) {
    text-align: right;
}

.sub-equipment-row td:nth-of-type(3) {
    text-align: left !important;
}




`, "",{"version":3,"sources":["webpack://./src/pages/configs/deliverable/equipmentList/components/SubEquipmentRow.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".sub-equipment-row {\n    background-color: rgb(245, 245, 245);\n    color: var(--dark-grey);\n}\n\n.sub-equipment-row th:nth-of-type(2), \n.sub-equipment-row td:nth-of-type(2) {\n    padding-left: 24px;\n}\n\n.sub-equipment-row th {\n    font-size: 14px;\n    color: var(--medium-grey);\n}\n\n.sub-equipment-row th:nth-of-type(4) {\n    text-align: right;\n}\n\n.sub-equipment-row td:nth-of-type(3) {\n    text-align: left !important;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
