import axios from '../conf/axios.conf';

//#region [GET]
export const fetchModules = async (configSstId) => {
  try {
    const response = await axios.get(`/modules/${configSstId}/list`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [POST]
export const fetchUpperModulesNd = async (configSstId, body) => {
  try {
    const response = await axios.post(
      `/modules/${configSstId}/services/upperModules/nd`,
      body
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
