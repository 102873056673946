import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { updateSelectedHps } from '../../../../../api/configSst.api';
import Bloc from '../../../../../components/Bloc/Bloc';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import { FORM_STATUS } from '../../../../../constants';
import ConfigsContext from '../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../contexts/PopupContext';
import {
  isArrNullOrEmpty,
  isObjNullOrEmpty
} from '../../../../../utils/data.utils';
import {
  checkForSelectedHpsError,
  getSizingGridErrors
} from '../../../../../utils/heatpump.utils';
import ConfigFormPage from '../../../components/ConfigFormPage/ConfigFormPage';
import HpsCatalogModal from './HpsCatalogModal/HpsCatalogModal';
import './HpsSelectionPage.css';
import SelectedHpsSection from './sections/SelectedHpsSection/SelectedHpsSection';
import SizingSection from './sections/SizingSection/SizingSection';

const HpsSelectionPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config, setConfig, refreshConfig, modules } =
    useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [formStatus, setFormStatus] = useState(FORM_STATUS.ORIGIN);
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  //#endregion

  //#region [methods]
  const save = async () => {
    try {
      await updateSelectedHps(config.IsModular, config.ConfigsSst[0], modules);
      await refreshConfig();
    } catch (err) {
      throw err;
    }
  };

  const handleFormChange = (conf) => {
    setConfig(() => ({ ...conf }));
    setFormStatus(() => FORM_STATUS.DIRTY);
  };

  const handleSaveClick = async () => {
    try {
      setFormStatus(() => FORM_STATUS.SAVING);
      await save();
      setFormStatus(() => FORM_STATUS.SAVED);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
      setFormStatus(() => FORM_STATUS.DIRTY);
    }
  };
  //#endregion

  //#region [render]
  const { services, heatpumps, pumps } = config.ConfigsSst[0].Data;
  const selectedHpsErrors = checkForSelectedHpsError(
    heatpumps.list,
    services.needs
  );
  const levelsErrors = getSizingGridErrors(config);
  const formErrors = selectedHpsErrors || !isObjNullOrEmpty(levelsErrors);

  return (
    <ConfigFormPage formStatus={formStatus} formErrors={formErrors} save={save}>
      <Bloc title={i18n._('config.hps.selection.title')}></Bloc>
      <Button
        className='hps-selection-btn'
        onClick={() => setIsCatalogOpen(true)}
      >
        <FontAwesomeIcon icon='list' />
        {i18n._('config.hps.selection.catalog')}
      </Button>
      {!isArrNullOrEmpty(heatpumps.list) && (
        <div className='hps-selection-tables'>
          <SelectedHpsSection onFormChange={handleFormChange} />
          {!selectedHpsErrors && (
            <SizingSection
              onFormChange={handleFormChange}
              levelsErrors={levelsErrors}
            />
          )}
        </div>
      )}
      <SaveButton
        disabled={formErrors}
        formStatus={formStatus}
        onClick={handleSaveClick}
      />
      {isCatalogOpen && (
        <HpsCatalogModal
          isOpen={isCatalogOpen}
          onClose={() => setIsCatalogOpen(false)}
          onFormChange={handleFormChange}
        />
      )}
    </ConfigFormPage>
  );
  //#endregion
};

export default HpsSelectionPage;
