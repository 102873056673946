import { isNull } from './data.utils';

export const getFormValue = (projectValue, param) => {
  if (isNull(projectValue)) {
    return null;
  } else if (param.type !== 'number') {
    return projectValue;
  } else {
    return +projectValue;
  }
};

export const getInitialForm = (
  project,
  params,
  initTableKeys,
  otherValuesKeys
) => {
  const form = {};
  if (!project || !params) return form;
  initTableKeys?.forEach((key) => {
    const param = params.find((param) => param.key === key);
    form[key] = getFormValue(project[key], param);
  });
  otherValuesKeys?.forEach((key) => {
    const param = params.find((param) => param.key === key);
    form[key] = getFormValue(project.otherValues[key], param);
  });
  return form;
};

export const getInitialSstForm = (substation, params, initStationTableKeys) => {
  const form = {};
  if (!substation || !params) return form;
  initStationTableKeys?.forEach((key) => {
    const param = params.find((param) => param.key === key);
    form[key] = getFormValue(substation[key], param);
  });
  return form;
};

export const getInputError = (i18n, value, param) => {
  // le champ est vide et non-obligatoire : pas d'erreur
  if (
    !param.required &&
    (isNull(value) || value.toString().trim().length === 0)
  )
    return null;

  let error;
  if (
    param.required &&
    (isNull(value) || value.toString().trim().length === 0)
  ) {
    // le param est obligatoire mais la valeur est manquante
    error = i18n._('input.error.required');
  } else if (param.type === 'number') {
    // le param est un nombre
    if (isNaN(value)) {
      error = i18n._('input.error.notNumber');
    } else if (param.min !== null && +value < param.min) {
      error = i18n._('input.error.min', { min: param.min });
    } else if (param.max !== null && +value > param.max) {
      error = i18n._('input.error.max', { max: param.max });
    } else if (param.exclusiveMin !== null && +value <= param.exclusiveMin) {
      error = i18n._('input.error.exclusiveMin', { min: param.exclusiveMin });
    } else if (param.exclusiveMax !== null && +value >= param.exclusiveMax) {
      error = i18n._('input.error.exclusiveMax', { max: param.exclusiveMax });
    }
  } else if (param.type === 'string' || param.type === 'password') {
    value = value.toString();
    if (param.minLength && value.length < param.minLength) {
      error = i18n._('input.error.minLength', { min: param.minLength });
    } else if (param.maxLength && value.length > param.maxLength) {
      error = i18n._('input.error.maxLength', { max: param.maxLength });
    } else if (param.pattern && !param.pattern.test(value)) {
      error = i18n._('input.error.pattern');
    }
  }
  return error;
};