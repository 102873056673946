// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.geostorage-hydraulic-bloc .custom-table thead tr th:first-of-type,
.geostorage-hydraulic-bloc .custom-table tbody tr td:first-of-type {
  width: 110px;
  min-width: 110px;
  font-weight: 600;
  text-align: center;
}

.geostorage-hydraulic-bloc .custom-table thead th:not(:first-of-type),
.geostorage-hydraulic-bloc .custom-table tr td:not(:first-of-type) {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 180px;
  text-align: start;
}

.geostorage-hydraulic-bloc .custom-table tr {
  height: 45px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/geostorage/hydraulic/HydraulicPage.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;EAEE,0BAA0B;EAC1B,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".geostorage-hydraulic-bloc .custom-table thead tr th:first-of-type,\n.geostorage-hydraulic-bloc .custom-table tbody tr td:first-of-type {\n  width: 110px;\n  min-width: 110px;\n  font-weight: 600;\n  text-align: center;\n}\n\n.geostorage-hydraulic-bloc .custom-table thead th:not(:first-of-type),\n.geostorage-hydraulic-bloc .custom-table tr td:not(:first-of-type) {\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  min-width: 180px;\n  text-align: start;\n}\n\n.geostorage-hydraulic-bloc .custom-table tr {\n  height: 45px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
