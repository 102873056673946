// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configs-header {
  position: sticky;
  top: 67px;
  margin-bottom: 15px;
  z-index: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.configs-header-select {
  width: 500px;
  margin-right: 15px;
}

.config-header-click-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.configs-header-delete-icon {
  font-size: 18px;
  color: var(--danger-color);
  cursor: pointer;
  margin: 0 8px 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,0BAA0B;EAC1B,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,yBAAyB;MACrB,sBAAsB;UAClB,8BAA8B;EACtC,WAAW;AACb;;AAEA;EACE,eAAe;EACf,0BAA0B;EAC1B,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".configs-header {\n  position: sticky;\n  top: 67px;\n  margin-bottom: 15px;\n  z-index: 300;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n\n.configs-header-select {\n  width: 500px;\n  margin-right: 15px;\n}\n\n.config-header-click-area {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  width: 100%;\n}\n\n.configs-header-delete-icon {\n  font-size: 18px;\n  color: var(--danger-color);\n  cursor: pointer;\n  margin: 0 8px 0 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
