import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PV_EQUIPMENT } from '../../../../../../../../../server/constants';
import {
  getBoundsConstraints,
  getSstBoundsConstraints
} from '../../../../../../../../../server/models/design/calculationData.model';
import {
  getProjectsUsingParam,
  isCalculationDataBoundPresent
} from '../../../../../../../utils/calculationData.utils';
import KilowattCretePVModal from '../../../components/KilowattCretePVModal';
import './OptiSizingSection.css';
import MinMaxInputs from './components/MinMaxInputs';
import MinMaxSelects from './components/MinMaxSelects';

const OptiSizingSection = ({
  project,
  calculationData,
  selectedProjects,
  onMinValueChange,
  onMaxValueChange,
  onSstMinValueChange,
  onSstMaxValueChange,
  onBtesLengthMaxValueChange,
  onSTSurfaceMaxValueChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const constraints = useMemo(
    () => getBoundsConstraints(selectedProjects),
    [selectedProjects]
  );

  // todo implémenter la multi sous-stations
  const sstConstraints = useMemo(
    () => getSstBoundsConstraints(null, selectedProjects),
    [selectedProjects]
  );
  //#endregion

  //#region [render]
  // todo implémenter la multi sous-stations pour les paramètres HPAPowerMax et GasPowerMax
  return (
    <div className='opti-section'>
      <h2>{i18n._('compute.opti.sizingParams')}</h2>
      <Row>
        <Col xs={6}></Col>
        <Col className='opti-sizing-section-header ps-2 pe-0' xs={3}>
          {i18n._('compute.opti.min')}
        </Col>
        <Col className='opti-sizing-section-header ps-2 pe-0' xs={3}>
          {i18n._('compute.opti.max')}
        </Col>
      </Row>
      {isCalculationDataBoundPresent(
        constraints.BtesLength.key,
        calculationData
      ) && (
        <MinMaxInputs
          label={i18n._('compute.BtesLength')}
          param={constraints.BtesLength}
          minValue={calculationData.constraints.bounds[0].BtesLength}
          maxValue={calculationData.constraints.bounds[1].BtesLength}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            constraints.BtesLength.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={(_, value) => onBtesLengthMaxValueChange(value)}
        />
      )}
      {isCalculationDataBoundPresent(
        constraints.BtesProbeDepth.key,
        calculationData
      ) && (
        <MinMaxSelects
          label={i18n._('compute.BtesProbeDepth')}
          param={constraints.BtesProbeDepth}
          minValue={calculationData.constraints.bounds[0].BtesProbeDepth}
          maxValue={calculationData.constraints.bounds[1].BtesProbeDepth}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            constraints.BtesProbeDepth.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={onMaxValueChange}
        />
      )}
      {isCalculationDataBoundPresent(
        constraints.BtesProbeDistance.key,
        calculationData
      ) && (
        <MinMaxSelects
          label={i18n._('compute.BtesProbeDistance')}
          param={constraints.BtesProbeDistance}
          minValue={calculationData.constraints.bounds[0].BtesProbeDistance}
          maxValue={calculationData.constraints.bounds[1].BtesProbeDistance}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            constraints.BtesProbeDistance.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={onMaxValueChange}
        />
      )}
      {isCalculationDataBoundPresent(
        constraints.T2final_Btes.key,
        calculationData
      ) && (
        <MinMaxInputs
          label={i18n._('compute.T2final_Btes')}
          param={constraints.T2final_Btes}
          minValue={calculationData.constraints.bounds[0].T2final_Btes}
          maxValue={calculationData.constraints.bounds[1].T2final_Btes}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            constraints.T2final_Btes.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={onMaxValueChange}
        />
      )}
      {isCalculationDataBoundPresent(
        constraints.HPGHeatBtesPowerMax.key,
        calculationData
      ) && (
        <MinMaxInputs
          label={i18n._('compute.HPGHeatBtesPowerMax')}
          param={constraints.HPGHeatBtesPowerMax}
          minValue={calculationData.constraints.bounds[0].HPGHeatBtesPowerMax}
          maxValue={calculationData.constraints.bounds[1].HPGHeatBtesPowerMax}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            constraints.HPGHeatBtesPowerMax.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={onMaxValueChange}
          maxFormula={i18n._('compute.HPGHeatBtesPowerMax.formula')}
        />
      )}
      {isCalculationDataBoundPresent(
        constraints.HPGColdBtesPowerMax.key,
        calculationData
      ) && (
        <MinMaxInputs
          label={i18n._('compute.HPGColdBtesPowerMax')}
          param={constraints.HPGColdBtesPowerMax}
          minValue={calculationData.constraints.bounds[0].HPGColdBtesPowerMax}
          maxValue={calculationData.constraints.bounds[1].HPGColdBtesPowerMax}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            constraints.HPGColdBtesPowerMax.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={onMaxValueChange}
          maxFormula={i18n._('compute.HPGColdBtesPowerMax.formula')}
        />
      )}
      {isCalculationDataBoundPresent(
        sstConstraints.HPAPowerMax.key,
        calculationData
      ) && (
        <MinMaxInputs
          label={i18n._('compute.HPAPowerMax')}
          param={sstConstraints.HPAPowerMax}
          minValue={calculationData.constraints.bounds[0].HPAPowerMax[0]}
          maxValue={calculationData.constraints.bounds[1].HPAPowerMax[0]}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            sstConstraints.HPAPowerMax.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onSstMinValueChange}
          onMaxValueChange={onSstMaxValueChange}
          maxFormula={i18n._('compute.HPAPowerMax.formula')}
        />
      )}
      {isCalculationDataBoundPresent(
        sstConstraints.GasPowerMax.key,
        calculationData
      ) && (
        <MinMaxInputs
          label={i18n._('compute.GasPowerMax')}
          param={sstConstraints.GasPowerMax}
          minValue={calculationData.constraints.bounds[0].GasPowerMax[0]}
          maxValue={calculationData.constraints.bounds[1].GasPowerMax[0]}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            sstConstraints.GasPowerMax.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onSstMinValueChange}
          onMaxValueChange={onSstMaxValueChange}
          maxFormula={i18n._('compute.GasPowerMax.formula')}
        />
      )}
      {isCalculationDataBoundPresent(
        constraints.SolarThermalSurface.key,
        calculationData
      ) && (
        <MinMaxInputs
          label={i18n._('compute.SolarThermalSurface')}
          param={constraints.SolarThermalSurface}
          minValue={calculationData.constraints.bounds[0].SolarThermalSurface}
          maxValue={calculationData.constraints.bounds[1].SolarThermalSurface}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            constraints.SolarThermalSurface.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={(_, value) => onSTSurfaceMaxValueChange(value)}
        />
      )}
      {isCalculationDataBoundPresent(
        constraints.KiloWattCretePV.key,
        calculationData
      ) && (
        <MinMaxInputs
          label={i18n._('compute.KiloWattCretePV')}
          param={constraints.KiloWattCretePV}
          minValue={calculationData.constraints.bounds[0].KiloWattCretePV}
          maxValue={calculationData.constraints.bounds[1].KiloWattCretePV}
          disabled={
            !!calculationData.constraints.bounds[1].SolarThermalSurface &&
            project.otherValues.InitPhotovoltaicModel ===
              PV_EQUIPMENT.DUALSUN.value
          }
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            constraints.KiloWattCretePV.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={onMaxValueChange}
          modalInfo={{
            title: i18n._('compute.KiloWattCretePV'),
            body: <KilowattCretePVModal />
          }}
        />
      )}
      {isCalculationDataBoundPresent(
        constraints.QItesMax.key,
        calculationData
      ) && (
        <MinMaxInputs
          label={i18n._('compute.QItesMax')}
          param={constraints.QItesMax}
          minValue={calculationData.constraints.bounds[0].QItesMax}
          maxValue={calculationData.constraints.bounds[1].QItesMax}
          disabled={!!calculationData.constraints.bounds[1].BtesLength}
          showBadges={selectedProjects.length > 1}
          projectsUsingParam={getProjectsUsingParam(
            i18n,
            constraints.QItesMax.key,
            project,
            selectedProjects
          )}
          onMinValueChange={onMinValueChange}
          onMaxValueChange={onMaxValueChange}
        />
      )}
    </div>
  );
  //#endregion
};

export default OptiSizingSection;
