const { UNIT } = require('../../../constants');

const DESCRIPTION = Object.freeze({
  Name: {
    key: 'Name',
    default: null,
    maxLength: 255
  }
});

const MODULAR = Object.freeze({
  IsModular: {
    key: 'IsModular',
    default: true
  }
});

const SST = Object.freeze({
  Name: {
    key: 'Name',
    type: 'text',
    required: true,
    default: null,
    maxLength: 255
  },
  Surface: {
    key: 'Surface',
    type: 'number',
    required: true,
    min: 100,
    max: 1000000,
    default: null,
    unit: UNIT.SQUARE_METER
  }
});

module.exports = { DESCRIPTION, MODULAR, SST };
