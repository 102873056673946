const {
  EXCHANGER,
  GAS,
  HP,
  ITES,
  HEADLOSS,
  GEOCOOLING,
  getSTParams,
  getPVParams
} = require('../models/design/performance.model');

const stParams = getSTParams();
const pvParams = getPVParams();
const PERFORMANCE_SCHEMA = {
  type: 'object',
  properties: {
    [EXCHANGER.InitLoopExchangerPinch.key]: {
      type: EXCHANGER.InitLoopExchangerPinch.type,
      minimum: EXCHANGER.InitLoopExchangerPinch.min,
      maximum: EXCHANGER.InitLoopExchangerPinch.max
    },
    [EXCHANGER.InitLoopExchangerU.key]: {
      type: EXCHANGER.InitLoopExchangerU.type,
      minimum: EXCHANGER.InitLoopExchangerU.min,
      maximum: EXCHANGER.InitLoopExchangerU.max
    },
    [EXCHANGER.InitLoopExchangerAirPinch.key]: {
      type: EXCHANGER.InitLoopExchangerAirPinch.type,
      minimum: EXCHANGER.InitLoopExchangerAirPinch.min,
      maximum: EXCHANGER.InitLoopExchangerAirPinch.max
    },
    [EXCHANGER.InitLoopExchangerAirU.key]: {
      type: EXCHANGER.InitLoopExchangerAirU.type,
      minimum: EXCHANGER.InitLoopExchangerAirU.min,
      maximum: EXCHANGER.InitLoopExchangerAirU.max
    },
    [EXCHANGER.InitStationExchangerPinch.key]: {
      type: EXCHANGER.InitStationExchangerPinch.type,
      minimum: EXCHANGER.InitStationExchangerPinch.min,
      maximum: EXCHANGER.InitStationExchangerPinch.max
    },
    [EXCHANGER.InitStationColdExchangerPinch.key]: {
      type: EXCHANGER.InitStationColdExchangerPinch.type,
      minimum: EXCHANGER.InitStationColdExchangerPinch.min,
      maximum: EXCHANGER.InitStationColdExchangerPinch.max
    },
    [EXCHANGER.InitStationHotwaterExchangerPinch.key]: {
      type: EXCHANGER.InitStationHotwaterExchangerPinch.type,
      minimum: EXCHANGER.InitStationHotwaterExchangerPinch.min,
      maximum: EXCHANGER.InitStationHotwaterExchangerPinch.max
    },
    [stParams.InitSolarThermalModel.key]: {
      type: stParams.InitSolarThermalModel.type
    },
    [stParams.InitSolarSurfaceTilt.key]: {
      type: stParams.InitSolarSurfaceTilt.type,
      minimum: stParams.InitSolarSurfaceTilt.min,
      maximum: stParams.InitSolarSurfaceTilt.max
    },
    [stParams.InitSolarSurfaceAzimuth.key]: {
      type: stParams.InitSolarSurfaceAzimuth.type,
      minimum: stParams.InitSolarSurfaceAzimuth.min,
      maximum: stParams.InitSolarSurfaceAzimuth.max
    },
    [stParams.InitSolarThermalHeta0.key]: {
      type: stParams.InitSolarThermalHeta0.type,
      minimum: stParams.InitSolarThermalHeta0.min,
      maximum: stParams.InitSolarThermalHeta0.max
    },
    [stParams.InitSolarThermalB1.key]: {
      type: stParams.InitSolarThermalB1.type,
      minimum: stParams.InitSolarThermalB1.min,
      maximum: stParams.InitSolarThermalB1.max
    },
    [stParams.InitSolarThermalBu.key]: {
      type: stParams.InitSolarThermalBu.type,
      minimum: stParams.InitSolarThermalBu.min,
      maximum: stParams.InitSolarThermalBu.max
    },
    [stParams.InitSolarThermalEpsa.key]: {
      type: stParams.InitSolarThermalEpsa.type,
      minimum: stParams.InitSolarThermalEpsa.min,
      maximum: stParams.InitSolarThermalEpsa.max
    },
    [stParams.InitSolarThermalB2.key]: {
      type: stParams.InitSolarThermalB2.type,
      minimum: stParams.InitSolarThermalB2.min,
      maximum: stParams.InitSolarThermalB2.max
    },
    [stParams.InitSolarThermalS.key]: {
      type: stParams.InitSolarThermalS.type,
      minimum: stParams.InitSolarThermalS.min
    },
    [stParams.InitSolarThermalHeadlossMceMax.key]: {
      type: stParams.InitSolarThermalHeadlossMceMax.type,
      minimum: stParams.InitSolarThermalHeadlossMceMax.min
    },
    [stParams.InitSolarThermalFlowRate.key]: {
      type: stParams.InitSolarThermalFlowRate.type,
      minimum: stParams.InitSolarThermalFlowRate.min,
      maximum: stParams.InitSolarThermalFlowRate.max
    },
    [stParams.InitSolarDeltaTemp.key]: {
      type: stParams.InitSolarDeltaTemp.type
    },
    [stParams.InitSolarTemperatureMin.key]: {
      type: stParams.InitSolarTemperatureMin.type
    },
    [stParams.InitSolarInjectionRatio.key]: {
      type: stParams.InitSolarInjectionRatio.type,
      minimum: stParams.InitSolarInjectionRatio.min,
      maximum: stParams.InitSolarInjectionRatio.max
    },
    [pvParams.InitPhotovoltaicModel.key]: {
      type: pvParams.InitPhotovoltaicModel.type
    },
    [pvParams.InitPVEfficiency.key]: {
      type: pvParams.InitPVEfficiency.type,
      minimum: pvParams.InitPVEfficiency.min,
      maximum: pvParams.InitPVEfficiency.max
    },
    [pvParams.InitPVTemperatureCoefGammaModule.key]: {
      type: pvParams.InitPVTemperatureCoefGammaModule.type,
      minimum: pvParams.InitPVTemperatureCoefGammaModule.min,
      maximum: pvParams.InitPVTemperatureCoefGammaModule.max
    },
    [pvParams.InitPVDCLossesAgeModule.key]: {
      type: pvParams.InitPVDCLossesAgeModule.type,
      minimum: pvParams.InitPVDCLossesAgeModule.min,
      maximum: pvParams.InitPVDCLossesAgeModule.max
    },
    [pvParams.InitPVInverterMaxPowerPerWc.key]: {
      type: pvParams.InitPVInverterMaxPowerPerWc.type
    },
    [pvParams.InitPVInverterNominalEfficiency.key]: {
      type: pvParams.InitPVInverterNominalEfficiency.type,
      minimum: pvParams.InitPVInverterNominalEfficiency.min,
      maximum: pvParams.InitPVInverterNominalEfficiency.max
    },
    [pvParams.InitPVMountingType.key]: {
      type: pvParams.InitPhotovoltaicModel.type
    },
    [GAS.InitGasEfficiency.key]: {
      type: GAS.InitGasEfficiency.type,
      minimum: GAS.InitGasEfficiency.min,
      maximum: GAS.InitGasEfficiency.max
    },
    [HP.InitStationCondTemperatureDelta.key]: {
      type: HP.InitStationCondTemperatureDelta.type,
      minimum: HP.InitStationCondTemperatureDelta.min,
      maximum: HP.InitStationCondTemperatureDelta.max
    },
    [HP.HPGFileID.key]: {
      type: HP.HPGFileID.type,
      pattern: HP.HPGFileID.pattern.toString().slice(1, -1)
    },
    [HP.HPAFileID.key]: {
      type: HP.HPAFileID.type,
      pattern: HP.HPAFileID.pattern.toString().slice(1, -1)
    },
    [HP.HPGFilename.key]: {
      type: HP.HPGFilename.type
    },
    [HP.HPAFilename.key]: {
      type: HP.HPAFilename.type
    },
    [HP.InitInjectionTemperatureDelta.key]: {
      type: HP.InitInjectionTemperatureDelta.type,
      minimum: HP.InitInjectionTemperatureDelta.min
    },
    [HP.InitInjectionTemperatureMin.key]: {
      type: HP.InitInjectionTemperatureMin.type,
      minimum: HP.InitInjectionTemperatureMin.min
    },
    [HP.HPAPenaltyTemperatureMin.key]: {
      type: HP.HPAPenaltyTemperatureMin.type,
      minimum: HP.HPAPenaltyTemperatureMin.min,
      maximum: HP.HPAPenaltyTemperatureMin.max
    },
    [HP.HPAPenaltyTemperatureMax.key]: {
      type: HP.HPAPenaltyTemperatureMax.type,
      minimum: HP.HPAPenaltyTemperatureMax.min,
      maximum: HP.HPAPenaltyTemperatureMax.max
    },
    [HP.HPAPenaltyPercentageMax.key]: {
      type: HP.HPAPenaltyPercentageMax.type,
      minimum: HP.HPAPenaltyPercentageMax.min
    },
    [ITES.InitItesModel.key]: {
      type: ITES.InitItesModel.type
    },
    [ITES.InitItesPumpConsumptionPercentage.key]: {
      type: ITES.InitItesPumpConsumptionPercentage.type,
      minimum: ITES.InitItesPumpConsumptionPercentage.min,
      maximum: ITES.InitItesPumpConsumptionPercentage.max
    },
    [ITES.InitItesChargingTemperatureIn.key]: {
      type: ITES.InitItesChargingTemperatureIn.type,
      minimum: ITES.InitItesChargingTemperatureIn.min,
      maximum: ITES.InitItesChargingTemperatureIn.max
    },
    [ITES.InitItesChargingTemperatureOut.key]: {
      type: ITES.InitItesChargingTemperatureOut.type,
      minimum: ITES.InitItesChargingTemperatureOut.min,
      maximum: ITES.InitItesChargingTemperatureOut.max
    },
    [ITES.InitItesDischargingTemperatureIn.key]: {
      type: ITES.InitItesDischargingTemperatureIn.type,
      minimum: ITES.InitItesDischargingTemperatureIn.min,
      maximum: ITES.InitItesDischargingTemperatureIn.max
    },
    [ITES.InitItesDischargingTemperatureOut.key]: {
      type: ITES.InitItesDischargingTemperatureOut.type,
      minimum: ITES.InitItesDischargingTemperatureOut.min,
      maximum: ITES.InitItesDischargingTemperatureOut.max
    },
    [HEADLOSS.InitPumpEfficiency.key]: {
      type: HEADLOSS.InitPumpEfficiency.type,
      minimum: HEADLOSS.InitPumpEfficiency.min,
      maximum: HEADLOSS.InitPumpEfficiency.max
    },
    [HEADLOSS.InitExchangerHeadlossMceMax.key]: {
      type: HEADLOSS.InitExchangerHeadlossMceMax.type,
      minimum: HEADLOSS.InitExchangerHeadlossMceMax.min
    },
    [HEADLOSS.InitBtesHeadlossLimit.key]: {
      type: HEADLOSS.InitBtesHeadlossLimit.type
    },
    [HEADLOSS.InitLoopConnectionLength.key]: {
      type: HEADLOSS.InitLoopConnectionLength.type,
      minimum: HEADLOSS.InitLoopConnectionLength.min
    },
    [GEOCOOLING.InitGeocoolingEnabled.key]: {
      type: GEOCOOLING.InitGeocoolingEnabled.type
    },
    [GEOCOOLING.InitGeocoolingTempOutBtesMax.key]: {
      type: GEOCOOLING.InitGeocoolingTempOutBtesMax.type,
      minimum: GEOCOOLING.InitGeocoolingTempOutBtesMax.min,
      maximum: GEOCOOLING.InitGeocoolingTempOutBtesMax.max
    },
    [GEOCOOLING.InitGeocoolingDisableClim.key]: {
      type: GEOCOOLING.InitGeocoolingDisableClim.type
    },
    [GEOCOOLING.InitGeocoolingPumpConsumptionPercent.key]: {
      type: GEOCOOLING.InitGeocoolingPumpConsumptionPercent.type,
      minimum: GEOCOOLING.InitGeocoolingPumpConsumptionPercent.min,
      maximum: GEOCOOLING.InitGeocoolingPumpConsumptionPercent.max
    }
  },
  required: [
    EXCHANGER.InitLoopExchangerPinch.key,
    EXCHANGER.InitLoopExchangerU.key,
    EXCHANGER.InitLoopExchangerAirPinch.key,
    EXCHANGER.InitLoopExchangerAirU.key,
    EXCHANGER.InitStationExchangerPinch.key,
    EXCHANGER.InitStationColdExchangerPinch.key,
    EXCHANGER.InitStationHotwaterExchangerPinch.key,
    stParams.InitSolarThermalModel.key,
    stParams.InitSolarSurfaceTilt.key,
    stParams.InitSolarSurfaceAzimuth.key,
    stParams.InitSolarThermalHeta0.key,
    stParams.InitSolarThermalB1.key,
    stParams.InitSolarThermalBu.key,
    stParams.InitSolarThermalEpsa.key,
    stParams.InitSolarThermalB2.key,
    stParams.InitSolarThermalHeadlossMceMax.key,
    stParams.InitSolarThermalFlowRate.key,
    stParams.InitSolarDeltaTemp.key,
    stParams.InitSolarTemperatureMin.key,
    stParams.InitSolarInjectionRatio.key,
    pvParams.InitPhotovoltaicModel.key,
    pvParams.InitPVEfficiency.key,
    pvParams.InitPVTemperatureCoefGammaModule.key,
    pvParams.InitPVDCLossesAgeModule.key,
    pvParams.InitPVInverterMaxPowerPerWc.key,
    pvParams.InitPVInverterNominalEfficiency.key,
    pvParams.InitPVMountingType.key,
    GAS.InitGasEfficiency.key,
    HP.InitStationCondTemperatureDelta.key,
    HP.HPGFileID.key,
    HP.HPAFileID.key,
    HP.HPGFilename.key,
    HP.HPAFilename.key,
    HP.InitInjectionTemperatureDelta.key,
    HP.InitInjectionTemperatureMin.key,
    HP.HPAPenaltyTemperatureMin.key,
    HP.HPAPenaltyTemperatureMax.key,
    HP.HPAPenaltyPercentageMax.key,
    ITES.InitItesModel.key,
    ITES.InitItesPumpConsumptionPercentage.key,
    ITES.InitItesChargingTemperatureIn.key,
    ITES.InitItesChargingTemperatureOut.key,
    ITES.InitItesDischargingTemperatureIn.key,
    ITES.InitItesDischargingTemperatureOut.key,
    HEADLOSS.InitPumpEfficiency.key,
    HEADLOSS.InitExchangerHeadlossMceMax.key,
    HEADLOSS.InitBtesHeadlossLimit.key,
    HEADLOSS.InitLoopConnectionLength.key,
    GEOCOOLING.InitGeocoolingEnabled.key,
    GEOCOOLING.InitGeocoolingTempOutBtesMax.key,
    GEOCOOLING.InitGeocoolingDisableClim.key,
    GEOCOOLING.InitGeocoolingPumpConsumptionPercent.key
  ],
  allOf: [
    {
      if: {
        properties: { [GEOCOOLING.InitGeocoolingEnabled.key]: { const: false } }
      },
      then: {
        properties: {
          [GEOCOOLING.InitGeocoolingTempOutBtesMax.key]: {
            const: GEOCOOLING.InitGeocoolingTempOutBtesMax.default
          }
        }
      }
    },
    {
      if: {
        properties: {
          [GEOCOOLING.InitGeocoolingDisableClim.key]: { const: false }
        }
      },
      then: {
        properties: {
          [GEOCOOLING.InitGeocoolingPumpConsumptionPercent.key]: {
            const: GEOCOOLING.InitGeocoolingPumpConsumptionPercent.default
          }
        }
      }
    }
  ],
  additionalProperties: false
};

module.exports = { PERFORMANCE_SCHEMA };
