// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptions-table th,
.descriptions-table td {
  font-size: 14px;
  word-break: break-word;
}

.descriptions-table th {
  color: white;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.descriptions-table-value {
  text-align: center;
  padding: 5px;
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ProjectSummary/sections/description/components/DescriptionsTable/DescriptionsTable.css"],"names":[],"mappings":"AAAA;;EAEE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".descriptions-table th,\n.descriptions-table td {\n  font-size: 14px;\n  word-break: break-word;\n}\n\n.descriptions-table th {\n  color: white;\n  font-weight: 500;\n  padding: 5px;\n  text-align: center;\n}\n\n.descriptions-table-value {\n  text-align: center;\n  padding: 5px;\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
