import { useLingui } from '@lingui/react';
import React from 'react';
import { UNIT } from '../../../../../../../../../../server/constants';
import { formatValueWithUnit } from '../../../../../../../../utils/data.utils';

const MaxPerDescriptionModal = ({ max, formula }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <div className='max-modal'>
      <p>{i18n._('compute.simu.maxModalPerDescription.top')}</p> <br />
      <p>{formula}</p>
      <br />
      <p>
        {i18n._('compute.simu.maxModal.bottom')}
        <span>{formatValueWithUnit(i18n, max, UNIT.KILOWATT)}</span>
      </p>
    </div>
  );
  //#endregion
};

export default React.memo(MaxPerDescriptionModal);
