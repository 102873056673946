import { useLingui } from '@lingui/react';
import React, { Fragment } from 'react';
import { isArrNullOrEmpty, roundUp } from '../../../../../utils/data.utils';
import './SubEquipmentRow.css';

const SubEquipmentRow = ({ list }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  if (isArrNullOrEmpty(list)) return;
  return (
    <Fragment>
      <tr className='sub-equipment-row'>
        <th></th>
        <th>{i18n._('config.equipmentList.details.equipment')}</th>
        <th>{i18n._('config.equipmentList.details.supplier')}</th>
        <th>{i18n._('config.equipmentList.details.total')}</th>
        <th colSpan={6}></th>
      </tr>
      {list.map((equipment, index) => (
        <tr key={`${index}_${equipment.name}`} className='sub-equipment-row'>
          <td></td>
          <td>{equipment.name}</td>
          <td>{equipment.supplier}</td>
          <td>{roundUp(equipment.price, 0)} €</td>
          <td colSpan={6}></td>
        </tr>
      ))}
    </Fragment>
  );
  //endregion
};
export default SubEquipmentRow;
