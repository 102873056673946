function getMaxHPGPowerMaxPerSst(sst, project) {
  if (!sst || !project) return 1000000;
  const {
    InitStationHasHeatNeeds,
    InitStationHasHotWaterNeeds,
    InitStationHasColdNeeds
  } = project.otherValues;
  const { maxHeatHotwater, maxCold, maxHeat, maxHotwater } = sst.summaryData;
  if (
    InitStationHasHeatNeeds &&
    InitStationHasHotWaterNeeds &&
    InitStationHasColdNeeds
  ) {
    return 2 * Math.max(maxHeatHotwater, maxCold);
  } else if (InitStationHasHeatNeeds && InitStationHasHotWaterNeeds) {
    return 2 * maxHeatHotwater;
  } else if (InitStationHasHeatNeeds && InitStationHasColdNeeds) {
    return 2 * Math.max(maxHeat, maxCold);
  } else if (InitStationHasHotWaterNeeds && InitStationHasColdNeeds) {
    return 2 * Math.max(maxHotwater, maxCold);
  } else if (InitStationHasHeatNeeds) {
    return 2 * maxHeat;
  } else if (InitStationHasHotWaterNeeds) {
    return 2 * maxHotwater;
  } else {
    return 2 * maxCold;
  }
}

function getMaxHPAPowerMaxPerSst(sst, project) {
  if (!sst || !project) return 1000000;
  const {
    InitStationHasHeatNeeds,
    InitStationHasHotWaterNeeds,
    InitStationHasColdNeeds
  } = project.otherValues;
  const { maxHeatHotwater, maxCold, maxHeat, maxHotwater } = sst.summaryData;
  if (
    InitStationHasHeatNeeds &&
    InitStationHasHotWaterNeeds &&
    InitStationHasColdNeeds
  ) {
    return 2 * Math.max(maxHeatHotwater, maxCold);
  } else if (InitStationHasHeatNeeds && InitStationHasHotWaterNeeds) {
    return 2 * maxHeatHotwater;
  } else if (InitStationHasHeatNeeds && InitStationHasColdNeeds) {
    return 2 * Math.max(maxHeat, maxCold);
  } else if (InitStationHasHotWaterNeeds && InitStationHasColdNeeds) {
    return 2 * Math.max(maxHotwater, maxCold);
  } else if (InitStationHasHeatNeeds) {
    return 2 * maxHeat;
  } else if (InitStationHasHotWaterNeeds) {
    return 2 * maxHotwater;
  } else {
    return 2 * maxCold;
  }
}

function getMaxGasPowerMaxPerSst(sst, project) {
  if (!sst || !project) return 1000000;
  const { InitStationHasHeatNeeds, InitStationHasHotWaterNeeds } =
    project.otherValues;
  const { maxHeat, maxHotwater, maxHeatHotwater } = sst.summaryData;
  if (InitStationHasHeatNeeds && InitStationHasHotWaterNeeds) {
    return 2 * maxHeatHotwater;
  } else if (InitStationHasHeatNeeds) {
    return 2 * maxHeat;
  } else {
    return 2 * maxHotwater;
  }
}

function getMaxHPGHeatBtesPowerMax(projects) {
  if (!projects) return 1000000;
  if (!Array.isArray(projects)) projects = [projects];
  let maxHPGHeatBtesPowerMax = 0;
  projects.forEach((project) => {
    const { InitStationHasHeatNeeds, InitStationHasHotWaterNeeds } =
      project.otherValues;
    const sumMaxHeatHotwater = project.substations.reduce((acc, sst) => {
      const { maxHeat, maxHotwater, maxHeatHotwater } = sst.summaryData;
      if (InitStationHasHeatNeeds && InitStationHasHotWaterNeeds) {
        return acc + maxHeatHotwater;
      } else if (InitStationHasHeatNeeds) {
        return acc + maxHeat;
      } else {
        return acc + maxHotwater;
      }
    }, 0);
    const tempMax = 2 * sumMaxHeatHotwater;
    if (maxHPGHeatBtesPowerMax < tempMax) {
      maxHPGHeatBtesPowerMax = tempMax;
    }
  });
  return maxHPGHeatBtesPowerMax;
}

function getMaxHPGColdBtesPowerMax(projects) {
  if (!projects) return 1000000;
  if (!Array.isArray(projects)) projects = [projects];
  let maxHPGColdBtesPowerMax = 0;
  projects.forEach((project) => {
    const sumMaxCold = project.substations.reduce((acc, sst) => {
      return acc + sst.summaryData.maxCold;
    }, 0);
    const tempMax = 2 * sumMaxCold;
    if (maxHPGColdBtesPowerMax < tempMax) {
      maxHPGColdBtesPowerMax = tempMax;
    }
  });
  return maxHPGColdBtesPowerMax;
}

function getMaxHPAPowerMax(projects) {
  if (!projects) return 1000000;
  if (!Array.isArray(projects)) projects = [projects];
  let maxHPAPowerMax = 0;
  projects.forEach((project) => {
    const { InitStationHasHeatNeeds, InitStationHasHotWaterNeeds } =
      project.otherValues;

    const sumMaxHeatHotwater = project.substations.reduce((acc, sst) => {
      const { maxHeat, maxHotwater, maxHeatHotwater } = sst.summaryData;
      if (InitStationHasHeatNeeds && InitStationHasHotWaterNeeds) {
        return acc + maxHeatHotwater;
      } else if (InitStationHasHeatNeeds) {
        return acc + maxHeat;
      } else {
        return acc + maxHotwater;
      }
    }, 0);

    const sumMaxCold = project.substations.reduce((acc, sst) => {
      return acc + sst.summaryData.maxCold;
    }, 0);

    const tempMax = 2 * Math.max(sumMaxHeatHotwater, sumMaxCold);
    if (maxHPAPowerMax < tempMax) maxHPAPowerMax = tempMax;
  });
  return maxHPAPowerMax;
}

function getMaxGasPowerMax(projects) {
  if (!projects) return 1000000;
  if (!Array.isArray(projects)) projects = [projects];
  let maxHPGHeatBtesPowerMax = 0;
  projects.forEach((project) => {
    const { InitStationHasHeatNeeds, InitStationHasHotWaterNeeds } =
      project.otherValues;
    const sumMaxHeatHotwater = project.substations.reduce((acc, sst) => {
      const { maxHeat, maxHotwater, maxHeatHotwater } = sst.summaryData;
      if (InitStationHasHeatNeeds && InitStationHasHotWaterNeeds) {
        return acc + maxHeatHotwater;
      } else if (InitStationHasHeatNeeds) {
        return acc + maxHeat;
      } else {
        return acc + maxHotwater;
      }
    }, 0);
    const tempMax = 2 * sumMaxHeatHotwater;
    if (maxHPGHeatBtesPowerMax < tempMax) {
      maxHPGHeatBtesPowerMax = tempMax;
    }
  });
  return maxHPGHeatBtesPowerMax;
}

function isBtesLengthAbsent(project) {
  return (
    project.otherValues.ItesEnabled || !project.otherValues.InitBtesEnabled
  );
}

function isBtesProbeDepthAbsent(project) {
  return (
    project.otherValues.ItesEnabled || !project.otherValues.InitBtesEnabled
  );
}

function isBtesProbeDistanceAbsent(project) {
  return (
    project.otherValues.ItesEnabled || !project.otherValues.InitBtesEnabled
  );
}

function isT2final_BtesAbsent(project) {
  return (
    project.otherValues.ItesEnabled || !project.otherValues.InitBtesEnabled
  );
}

function isHPGHeatBtesPowerMaxAbsent(project) {
  return (
    project.otherValues.ItesEnabled ||
    !project.otherValues.InitHPGHeatingEnabled ||
    (!project.otherValues.InitStationHasHeatNeeds &&
      !project.otherValues.InitStationHasHotWaterNeeds)
  );
}

function isHPGColdBtesPowerMaxAbsent(project) {
  return (
    project.otherValues.ItesEnabled ||
    !project.otherValues.InitHPGCoolingEnabled ||
    !project.otherValues.InitStationHasColdNeeds
  );
}

function isHPAPowerMaxAbsent(project) {
  return (
    !project.otherValues.InitHPAInjectionEnabled &&
    !project.otherValues.InitHPAHeatingEnabled &&
    !project.otherValues.InitHPACoolingEnabled
  );
}

function isGasPowerMaxAbsent(project) {
  return !project.otherValues.InitGasEnabled;
}

function isSolarThermalSurfaceAbsent(project) {
  return (
    project.otherValues.ItesEnabled ||
    (!project.otherValues.InitStationHasHeatNeeds &&
      !project.otherValues.InitStationHasHotWaterNeeds) ||
    !project.otherValues.InitSolarThermalEnabled
  );
}

function isPhotovoltaicAbsent(project) {
  return !project.otherValues.InitPhotovoltaicEnabled;
}

function isQItesMaxAbsent(project) {
  return !project.otherValues.ItesEnabled;
}

function isHPGPowerMaxAbsent(project) {
  return (
    project.otherValues.ItesEnabled ||
    (!project.otherValues.InitHPGHeatingEnabled &&
      !project.otherValues.InitHPGCoolingEnabled)
  );
}

module.exports = {
  getMaxHPGPowerMaxPerSst,
  getMaxHPAPowerMaxPerSst,
  getMaxGasPowerMaxPerSst,
  getMaxHPGHeatBtesPowerMax,
  getMaxHPGColdBtesPowerMax,
  getMaxHPAPowerMax,
  getMaxGasPowerMax,
  isBtesLengthAbsent,
  isBtesProbeDepthAbsent,
  isBtesProbeDistanceAbsent,
  isT2final_BtesAbsent,
  isHPGHeatBtesPowerMaxAbsent,
  isHPGColdBtesPowerMaxAbsent,
  isHPAPowerMaxAbsent,
  isGasPowerMaxAbsent,
  isSolarThermalSurfaceAbsent,
  isPhotovoltaicAbsent,
  isQItesMaxAbsent,
  isHPGPowerMaxAbsent
};
