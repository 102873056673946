// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simulation-form > .simulation-section {
  margin-top: 15px;
}

.simulation-form > .simulation-section > .row {
  margin-top: 15px;
}

.simulation-form > .simulation-section .col-5 {
  padding-right: 0;
}

.simulation-form .param-input-body {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/simulation/SimulationForm.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".simulation-form > .simulation-section {\n  margin-top: 15px;\n}\n\n.simulation-form > .simulation-section > .row {\n  margin-top: 15px;\n}\n\n.simulation-form > .simulation-section .col-5 {\n  padding-right: 0;\n}\n\n.simulation-form .param-input-body {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
