import React from 'react';

const HeaderCell = ({ name, isSelected, isSelectable, onHeaderClick }) => {
  //#region [render]
  return isSelectable ? (
    <span
      className={`detailed-header selectable${isSelected ? ' bold' : ''}`}
      onClick={onHeaderClick}
    >
      {name}
    </span>
  ) : (
    <span className='detailed-header'>{name}</span>
  );
  //#endregion
};

export default React.memo(HeaderCell);
