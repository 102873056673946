const { HP_TYPE, UPPER_TAPPING_ND } = require('../../../server/constants');

const formatEquipmentDetails = (selectedModule) => {
  // on récupère les informations du module en bdd
  let newModule = {};
  Object.entries(selectedModule).forEach(([key, value]) => {
    const formatedKey = key.charAt(0).toLowerCase() + key.slice(1);
    return (newModule[formatedKey] = value);
  });
  return newModule;
};

//#region [PACs]
export const getHpLowerModuleKey = (hp) => {
  // TODO règle pour choisir le module incomplète (en attente de spec)
  const moduleKey = {
    [HP_TYPE.GEO]: 'M-Hpg-Wat',
    [HP_TYPE.AERO]: 'M-HpA-C-A'
  };
  return moduleKey[hp.type];
};

export const getHpLowerModuleSettings = (hp, selectedModule) => {
  const newLowerModule = formatEquipmentDetails(selectedModule);
  const moduleName = {
    [HP_TYPE.GEO]: `${newLowerModule.module}-DN${newLowerModule.nominalDiameter}`,
    [HP_TYPE.AERO]: `${newLowerModule.module}-DN${newLowerModule.nominalDiameter}`
  };
  newLowerModule.name = moduleName[hp.type];
  return newLowerModule;
};

export const getHpUpperModuleName = (hp, services) => {
  const width = hp.lowerModule.width ?? 1200; // TODO 1200 est une largeur par défaut temporaire
  const evapNd = services.upperModules.evaporator.nominalDiameter;
  const condNd = services.upperModules.condensor.nominalDiameter;
  const vdc = hp.collectorsEquipment.Vdc ? '-Vdc' : '';
  const vde = hp.collectorsEquipment.Vde ? '-Vde' : '';
  const phexx =
    hp.collectorsEquipment.upperEvapTapping !== UPPER_TAPPING_ND.ABSENT
      ? `-PhE${hp.collectorsEquipment.upperEvapTapping}`
      : '';
  const phcxx =
    hp.collectorsEquipment.upperCondTapping !== UPPER_TAPPING_ND.ABSENT
      ? `-PhC${hp.collectorsEquipment.upperCondTapping}`
      : '';
  return `COL-A-${
    width + 500
  }-E${evapNd}C${condNd}${vdc}${vde}${phexx}${phcxx}`;
};
//#endregion
