const { GENERAL, LOCATION } = require('./design/general.model');
const {
  NAME,
  NEEDS,
  ENERGY_STORAGE,
  GEOSTORAGE,
  HOTWATER_PRODUCTION,
  ELECTRIC_PRODUCTION,
  WEATHER,
  getHeatProductionParams,
  getColdProductionParams
} = require('./design/description.model');
const {
  EXCHANGER,
  GAS: PERF_GAS,
  HP,
  ITES: PERF_ITES,
  HEADLOSS,
  GEOCOOLING,
  getPVParams,
  getSTParams
} = require('./design/performance.model');
const { BTES, SOIL, getSoilTempParams } = require('./design/geology.model');
const {
  HPG,
  HPA,
  GAS,
  WATERTANK_HOT,
  WATERTANK_COLD,
  WATERTANK_HOTWATER,
  INJECTION,
  SOLAR,
  PV,
  ITES,
  HYDRAULIC,
  ELEC_CAPEX,
  OTHER_CAPEX,
  ELEC_OPEX,
  GAS_OPEX,
  OTHER_OPEX_ACCENTA,
  OTHER_OPEX_REF,
  getBtesParams
} = require('./design/cost.model');

const soilTempParams = getSoilTempParams();
const btesCostParams = getBtesParams();
const heatProdParams = getHeatProductionParams();
const coldProdParams = getColdProductionParams();
const perfPvParams = getPVParams();
const perfStParams = getSTParams();
const EMPTY_PROJECT = {
  AhsID: null,
  AhsCoID: null,
  ParentAhsID: null,
  CalculationID: null,
  WeatherFileID: WEATHER.WeatherFileID.default,
  ImageFileID: GENERAL.ImageFileID.default,
  updated_by: null,
  otherValues: {
    AhsName: GENERAL.AhsName.default,
    AhsDescription: GENERAL.AhsDescription.default,
    AhsAddress1: LOCATION.AhsAddress1.default,
    AhsCity: LOCATION.AhsCity.default,
    AhsCountry: LOCATION.AhsCountry.default,
    AhsMainUsage: GENERAL.AhsMainUsage.default,
    AhsType: GENERAL.AhsType.default,
    AhsZipCode: LOCATION.AhsZipCode.default,
    description: NAME.description.default,
    InitAltitude: LOCATION.InitAltitude.default,
    InitBuildingSurface: GENERAL.InitBuildingSurface.default,
    InitBtesCostA: btesCostParams.InitBtesCostA.default,
    InitBtesCostB: btesCostParams.InitBtesCostB.default,
    InitGasPrice: GAS_OPEX.InitGasPrice.default,
    InitGasPriceSubscription: GAS_OPEX.InitGasPriceSubscription.default,
    InitGasPriceEvol: GAS_OPEX.InitGasPriceEvol.default,
    InitItesCostA: ITES.InitItesCostA.default,
    InitItesCostB: ITES.InitItesCostB.default,
    InitCapexLotElecA: ELEC_CAPEX.InitCapexLotElecA.default,
    InitCapexLotElecB: ELEC_CAPEX.InitCapexLotElecB.default,
    InitLatitude: LOCATION.InitLatitude.default,
    InitLongitude: LOCATION.InitLongitude.default,
    InitPVModuleCostA: PV.InitPVModuleCostA.default,
    InitPVModuleCostB: PV.InitPVModuleCostB.default,
    InitPVModuleSupportCostA: PV.InitPVModuleSupportCostA.default,
    InitPVModuleSupportCostB: PV.InitPVModuleSupportCostB.default,
    InitPVInverterCostA: PV.InitPVInverterCostA.default,
    InitPVInverterCostB: PV.InitPVInverterCostB.default,
    InitSoilThermalConductivity: SOIL.InitSoilThermalConductivity.default,
    InitSoilVolumeHeatCapacity: SOIL.InitSoilVolumeHeatCapacity.default,
    InitBtesEnabled: ENERGY_STORAGE.InitBtesEnabled.default,
    ItesEnabled: ENERGY_STORAGE.ItesEnabled.default,
    InitHPAInjectionEnabled: GEOSTORAGE.InitHPAInjectionEnabled.default,
    InitLoopIsUsed: GEOSTORAGE.InitLoopIsUsed.default,
    InitLoopWaterType: GEOSTORAGE.InitLoopWaterType.default,
    InitStationHasHeatNeeds: NEEDS.InitStationHasHeatNeeds.default,
    InitStationHasColdNeeds: NEEDS.InitStationHasColdNeeds.default,
    InitStationHasHotWaterNeeds: NEEDS.InitStationHasHotWaterNeeds.default,
    InitHPGHeatingEnabled: heatProdParams.InitHPGHeatingEnabled.default,
    InitHPAHeatingEnabled: heatProdParams.InitHPAHeatingEnabled.default,
    InitWaterLawIsUsed: heatProdParams.InitWaterLawIsUsed.default,
    InitStationWaterTankHotTemperatureMin:
      heatProdParams.InitStationWaterTankHotTemperatureMin.default,
    InitStationTemperatureSetPoint:
      heatProdParams.InitStationTemperatureSetPoint.default,
    InitGasEnabled: heatProdParams.InitGasEnabled.default,
    InitReleveEnabled: heatProdParams.InitReleveEnabled.default,
    InitSolarThermalEnabled: heatProdParams.InitSolarThermalEnabled.default,
    InitTemperatureHeat: heatProdParams.InitTemperatureHeat.default,
    InitTemperatureHeatDelta: heatProdParams.InitTemperatureHeatDelta.default,
    InitHPGCoolingEnabled: coldProdParams.InitHPGCoolingEnabled.default,
    InitHPACoolingEnabled: coldProdParams.InitHPACoolingEnabled.default,
    InitTemperatureCold: coldProdParams.InitTemperatureCold.default,
    InitStationEvapTemperatureDelta:
      coldProdParams.InitStationEvapTemperatureDelta.default,
    InitStationCondTemperatureDelta: HP.InitStationCondTemperatureDelta.default,
    InitTemperatureHotWater:
      HOTWATER_PRODUCTION.InitTemperatureHotWater.default,
    InitTemperatureHotWaterBack:
      HOTWATER_PRODUCTION.InitTemperatureHotWaterBack.default,
    InitPhotovoltaicEnabled:
      ELECTRIC_PRODUCTION.InitPhotovoltaicEnabled.default,
    InitTrtChecked: soilTempParams.InitTrtChecked.default,
    InitSoilTemperature10m: soilTempParams.InitSoilTemperature10m.default,
    InitSoilTemperatureMean: soilTempParams.InitSoilTemperatureMean.default,
    InitTrtProbeDepth: soilTempParams.InitTrtProbeDepth.default,
    InitBtesTemperatureMin: BTES.InitBtesTemperatureMin.default,
    InitBtesTemperatureMax: BTES.InitBtesTemperatureMax.default,
    InitLoopExchangerAirPinch: EXCHANGER.InitLoopExchangerAirPinch.default,
    InitLoopExchangerAirU: EXCHANGER.InitLoopExchangerAirU.default,
    InitLoopExchangerPinch: EXCHANGER.InitLoopExchangerPinch.default,
    InitLoopExchangerU: EXCHANGER.InitLoopExchangerU.default,
    InitStationColdExchangerPinch:
      EXCHANGER.InitStationColdExchangerPinch.default,
    InitStationExchangerPinch: EXCHANGER.InitStationExchangerPinch.default,
    InitStationHotwaterExchangerPinch:
      EXCHANGER.InitStationHotwaterExchangerPinch.default,
    InitGasEfficiency: PERF_GAS.InitGasEfficiency.default,
    InitSolarThermalBu: perfStParams.InitSolarThermalBu.default,
    InitSolarThermalB1: perfStParams.InitSolarThermalB1.default,
    InitSolarThermalB2: perfStParams.InitSolarThermalB2.default,
    InitSolarThermalEpsa: perfStParams.InitSolarThermalEpsa.default,
    InitSolarThermalFlowRate: perfStParams.InitSolarThermalFlowRate.default,
    InitSolarThermalHeta0: perfStParams.InitSolarThermalHeta0.default,
    InitSolarThermalModel: perfStParams.InitSolarThermalModel.default,
    InitSolarThermalS: perfStParams.InitSolarThermalS.default,
    InitSolarThermalHeadlossMceMax:
      perfStParams.InitSolarThermalHeadlossMceMax.default,
    InitPumpEfficiency: HEADLOSS.InitPumpEfficiency.default,
    InitExchangerHeadlossMceMax: HEADLOSS.InitExchangerHeadlossMceMax.default,
    InitBtesHeadlossLimit: HEADLOSS.InitBtesHeadlossLimit.default,
    InitLoopConnectionLength: HEADLOSS.InitLoopConnectionLength.default,
    InitItesModel: PERF_ITES.InitItesModel.default,
    InitItesPumpConsumptionPercentage:
      PERF_ITES.InitItesPumpConsumptionPercentage.default,
    InitItesChargingTemperatureIn:
      PERF_ITES.InitItesChargingTemperatureIn.default,
    InitItesChargingTemperatureOut:
      PERF_ITES.InitItesChargingTemperatureOut.default,
    InitItesDischargingTemperatureIn:
      PERF_ITES.InitItesDischargingTemperatureIn.default,
    InitItesDischargingTemperatureOut:
      PERF_ITES.InitItesDischargingTemperatureOut.default,
    InitSolarDeltaTemp: perfStParams.InitSolarDeltaTemp.default,
    InitSolarTemperatureMin: perfStParams.InitSolarTemperatureMin.default,
    InitSolarSurfaceTilt: perfStParams.InitSolarSurfaceTilt.default,
    InitSolarSurfaceAzimuth: perfStParams.InitSolarSurfaceAzimuth.default,
    InitPhotovoltaicModel: perfPvParams.InitPhotovoltaicModel.default,
    InitPVEfficiency: perfPvParams.InitPVEfficiency.default,
    InitPVTemperatureCoefGammaModule:
      perfPvParams.InitPVTemperatureCoefGammaModule.default,
    InitPVInverterMaxPowerPerWc:
      perfPvParams.InitPVInverterMaxPowerPerWc.default,
    InitPVDCLossesAgeModule: perfPvParams.InitPVDCLossesAgeModule.default,
    InitPVInverterNominalEfficiency:
      perfPvParams.InitPVInverterNominalEfficiency.default,
    InitPVMountingType: perfPvParams.InitPVMountingType.default,
    HPGFileID: HP.HPGFileID.default,
    HPGFilename: HP.HPGFilename.default,
    HPAFileID: HP.HPAFileID.default,
    HPAFilename: HP.HPAFilename.default,
    InitBtesProbeResistance: BTES.InitBtesProbeResistance.default,
    InitCapexOther: OTHER_CAPEX.InitCapexOther.default,
    InitOpexGaPerf: OTHER_OPEX_ACCENTA.InitOpexGaPerf.default,
    InitOpexGaPerfEvol: OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol.default,
    InitOpexMaint: OTHER_OPEX_ACCENTA.InitOpexMaint.default,
    InitOpexMaintEvol: OTHER_OPEX_ACCENTA.InitOpexMaintEvol.default,
    InitOpexOther: OTHER_OPEX_ACCENTA.InitOpexOther.default,
    InitOpexOtherEvol: OTHER_OPEX_ACCENTA.InitOpexOtherEvol.default,
    InitRefOpexGaPerf: OTHER_OPEX_REF.InitRefOpexGaPerf.default,
    InitRefOpexGaPerfEvol: OTHER_OPEX_REF.InitRefOpexGaPerfEvol.default,
    InitRefOpexMaint: OTHER_OPEX_REF.InitRefOpexMaint.default,
    InitRefOpexMaintEvol: OTHER_OPEX_REF.InitRefOpexMaintEvol.default,
    InitRefOpexOther: OTHER_OPEX_REF.InitRefOpexOther.default,
    InitRefOpexOtherEvol: OTHER_OPEX_REF.InitRefOpexOtherEvol.default,
    InitElecPriceEvolMethod: ELEC_OPEX.InitElecPriceEvolMethod.default,
    InitElecPriceEvol: ELEC_OPEX.InitElecPriceEvol.default,
    InitPVElecToNetworkPriceEvol:
      ELEC_OPEX.InitPVElecToNetworkPriceEvol.default,
    InitPVElecToProjectPriceEvol:
      ELEC_OPEX.InitPVElecToProjectPriceEvol.default,
    InitGasPriceEvolMethod: GAS_OPEX.InitGasPriceEvolMethod.default,
    InitBtesTemperatureDelta: BTES.InitBtesTemperatureDelta.default,
    YearsT2Final: BTES.YearsT2Final.default,
    InitBtesMaxWidth: BTES.InitBtesMaxWidth.default,
    InitBtesProbeInternalDiameter: BTES.InitBtesProbeInternalDiameter.default,
    InitMOEBtesMargin: OTHER_CAPEX.InitMOEBtesMargin.default,
    InitMOEMargin: OTHER_CAPEX.InitMOEMargin.default,
    InitIntegrationBtesMargin: OTHER_CAPEX.InitIntegrationBtesMargin.default,
    InitIntegrationMargin: OTHER_CAPEX.InitIntegrationMargin.default,
    InitYearsBeforeStart: OTHER_OPEX_ACCENTA.InitYearsBeforeStart.default,
    InitSolarInjectionRatio: perfStParams.InitSolarInjectionRatio.default,
    InitInjectionTemperatureDelta: HP.InitInjectionTemperatureDelta.default,
    InitInjectionTemperatureMin: HP.InitInjectionTemperatureMin.default,
    HPAPenaltyTemperatureMin: HP.HPAPenaltyTemperatureMin.default,
    HPAPenaltyTemperatureMax: HP.HPAPenaltyTemperatureMax.default,
    HPAPenaltyPercentageMax: HP.HPAPenaltyPercentageMax.default,
    InitGeocoolingEnabled: GEOCOOLING.InitGeocoolingEnabled.default,
    InitGeocoolingDisableClim: GEOCOOLING.InitGeocoolingDisableClim.default,
    InitGeocoolingTempOutBtesMax:
      GEOCOOLING.InitGeocoolingTempOutBtesMax.default,
    InitGeocoolingPumpConsumptionPercent:
      GEOCOOLING.InitGeocoolingPumpConsumptionPercent.default,
    InitBtesDiscountDict: btesCostParams.InitBtesDiscountDict.default,
    InitHPGCost: HPG.InitHPGCost.default,
    InitHPACostHeat: HPA.InitHPACostHeat.default,
    InitHPACostHeatCold: HPA.InitHPACostHeatCold.default,
    InitCapexGas: GAS.InitCapexGas.default,
    InitWaterTankHotCostA: WATERTANK_HOT.InitWaterTankHotCostA.default,
    InitWaterTankHotCostB: WATERTANK_HOT.InitWaterTankHotCostB.default,
    InitWaterTankColdCostA: WATERTANK_COLD.InitWaterTankColdCostA.default,
    InitWaterTankColdCostB: WATERTANK_COLD.InitWaterTankColdCostB.default,
    InitWaterTankColdExchangerCostA:
      WATERTANK_COLD.InitWaterTankColdExchangerCostA.default,
    InitWaterTankColdExchangerCostB:
      WATERTANK_COLD.InitWaterTankColdExchangerCostB.default,
    InitWaterTankColdPumpCostA:
      WATERTANK_COLD.InitWaterTankColdPumpCostA.default,
    InitWaterTankColdPumpCostB:
      WATERTANK_COLD.InitWaterTankColdPumpCostB.default,
    InitWaterTankHotwaterCostA:
      WATERTANK_HOTWATER.InitWaterTankHotwaterCostA.default,
    InitWaterTankHotwaterCostB:
      WATERTANK_HOTWATER.InitWaterTankHotwaterCostB.default,
    InitWaterTankHotwaterExchangerCostA:
      WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA.default,
    InitWaterTankHotwaterExchangerCostB:
      WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB.default,
    InitWaterTankHotwaterPumpCostA:
      WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA.default,
    InitWaterTankHotwaterPumpCostB:
      WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB.default,
    InitInjectionExchangerCostA: INJECTION.InitInjectionExchangerCostA.default,
    InitInjectionExchangerCostB: INJECTION.InitInjectionExchangerCostB.default,
    InitInjectionPumpCostA: INJECTION.InitInjectionPumpCostA.default,
    InitInjectionPumpCostB: INJECTION.InitInjectionPumpCostB.default,
    InitSolarThermalPanelCostA: SOLAR.InitSolarThermalPanelCostA.default,
    InitSolarThermalVanneCost: SOLAR.InitSolarThermalVanneCost.default,
    InitSolarThermalPumpCostA: SOLAR.InitSolarThermalPumpCostA.default,
    InitSolarThermalPumpCostB: SOLAR.InitSolarThermalPumpCostB.default,
    InitCapexHydraulicMargin: HYDRAULIC.InitCapexHydraulicMargin.default
  }
};

module.exports = { EMPTY_PROJECT };
