import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import PopupContext from '../../../../../../contexts/PopupContext';
import { formatValue, isNull } from '../../../../../../utils/data.utils';
import { getInputError } from '../../../../../../utils/form.utils';
import MaxPerDescriptionModal from '../sections/SimuSizingSection/components/MaxPerDescriptionModal';

const SimuInput = ({
  label,
  value,
  param,
  disabled,
  modalInfo,
  maxFormula,
  onChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleValueChange = (evt) => {
    if (!onChange) return;
    const value =
      !isNull(evt.target.value) && evt.target.value !== ''
        ? +evt.target.value
        : evt.target.value;
    onChange(param, value);
  };
  //#endregion

  //#region [render]
  const inputId = `input_simu_${param.key}`;
  const inputError = disabled ? null : getInputError(i18n, value, param);
  const inputClassName = inputError ? ' param-input-error' : '';
  return (
    <Row>
      <Col xs='7'>
        <span className='param-input-label'>
          {label}
          {modalInfo && (
            <FontAwesomeIcon
              icon='circle-question'
              className='compute-info-icon modal-info-icon'
              onClick={() => openInfoModal(modalInfo.title, modalInfo.body)}
            />
          )}
        </span>
      </Col>
      <Col xs='5'>
        <InputGroup>
          <Form.Control
            className={`compute-input param-input${inputClassName}`}
            type={param.type}
            disabled={disabled}
            onChange={handleValueChange}
            value={value}
            data-tooltip-id={'error_' + inputId}
            data-tooltip-content={inputError}
            name={inputId}
          />
          {inputError && (
            <Tooltip
              id={'error_' + inputId}
              place='bottom'
              className='error-tooltip'
              arrowColor='#f04460'
              opacity={1}
            />
          )}
          {maxFormula && (
            <InputGroup.Text
              className='opti-input-max-icon'
              data-tooltip-content={i18n._('input.minMax.max', {
                max: formatValue(param.max, 0)
              })}
              data-tooltip-id={'info_' + inputId}
              onClick={() =>
                openInfoModal(
                  i18n._('compute.maxParam.title', { param: label }),
                  <MaxPerDescriptionModal
                    max={formatValue(param.max, 0)}
                    formula={maxFormula}
                  />
                )
              }
            >
              <FontAwesomeIcon icon='calculator' />
              <Tooltip
                id={'info_' + inputId}
                place='bottom'
                className='info-tooltip'
                opacity={1}
              />
            </InputGroup.Text>
          )}
        </InputGroup>
      </Col>
    </Row>
  );
  //#endregion
};

export default SimuInput;
