import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { ELEC_METER } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { getSortedNeeds } from '../../../../../../utils/config.utils';
import { isNull } from '../../../../../../utils/data.utils';
import NeedBadge from '../components/NeedBadge';

const HpsSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const checkForElecMeterWarning = (checked, defaultValue) =>
    isNull(defaultValue) ? false : defaultValue !== checked;

  const handleExchangerChange = (evt, hpId) => {
    try {
      const { checked, value } = evt.target;
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      list[index][value] = checked;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  const params = ELEC_METER.Ec0;
  return (
    <Section title={i18n._('config.hps.hydraulic.hps')} level={2} open>
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.hps.position')}</th>
              <th>{i18n._('config.hps.model')}</th>
              <th>{i18n._('config.hps.type')}</th>
              <th>{i18n._('config.hps.needs')}</th>
              <th>{i18n._(`hydraulicConfig.table.th.${params.key}`)}</th>
            </tr>
          </thead>
          <tbody>
            {list
              .sort((hp1, hp2) => hp1.position - hp2.position)
              .map((hp, index) => {
                const sortedNeeds = getSortedNeeds(hp.needs);
                const checked = hp[params.key];
                const warning = checkForElecMeterWarning(
                  checked,
                  params.default
                );
                const checkboxId = 'hp_elec' + index + params.key;
                return (
                  <tr key={'row_ex_' + index}>
                    <td>{hp.position}</td>
                    <td>{hp.model}</td>
                    <td>{i18n._(`heatpump.type.${hp.type}`)}</td>
                    <td>
                      <div className='equipment-table-badges'>
                        {sortedNeeds.map((need) => (
                          <NeedBadge
                            key={'badge_' + index + need}
                            need={need}
                          />
                        ))}
                      </div>
                    </td>
                    <td>
                      <div
                        data-tooltip-content={
                          warning
                            ? checked
                              ? i18n._('equipment.notChecked.warning')
                              : i18n._('equipment.checked.warning')
                            : null
                        }
                        data-tooltip-id={checkboxId}
                        style={{ width: 'fit-content' }}
                      >
                        <Form.Check
                          type='checkbox'
                          label={i18n._(
                            `hydraulicConfig.table.td.${params.key}`,
                            {
                              position: hp.position
                            }
                          )}
                          value={params.key}
                          onChange={(evt) => handleExchangerChange(evt, hp.id)}
                          checked={!!checked}
                          className={warning ? 'warning' : ''}
                          disabled={!isNull(params.enabled) && !params.enabled}
                          id={'hps_ex_' + params.key}
                        />
                        {warning &&
                          createPortal(
                            <Tooltip
                              id={checkboxId}
                              place='bottom'
                              className='error-tooltip'
                              arrowColor='#f04460'
                              opacity={1}
                            />,
                            document.body
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //#endregion
};

export default HpsSection;
