import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup, Overlay, Tooltip } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { isNull } from '../../utils/data.utils';
import { getInputError } from '../../utils/form.utils';
import './Form.css';

// todo supprimer et utiliser ParamInput + ajv (JsonSchema)
const FormInput = ({
  id,
  className,
  value,
  disabled,
  param,
  label,
  bottomText,
  topText,
  unit,
  type,
  infoIcon,
  infoTooltip,
  showInfoIcon,
  onBlur,
  onChange,
  onInfoClick,
  addError,
  removeError
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [error, setError] = useState(null);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  //#endregion

  //#region [refs]
  const oldValue = useRef(value);
  const inputRef = useRef();
  const iconRef = useRef(null);
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!inputRef || value === inputRef.current.value) return;
    inputRef.current.value = value;
  }, [value]);

  useEffect(() => {
    const val = inputRef.current.value;
    const inputErr = getInputError(i18n, val, param);
    if (!disabled) {
      if (!inputErr && error) {
        setError(null);
        if (removeError) removeError();
      } else if (inputErr && !error) {
        if (!error && addError) addError();
        setError(inputErr);
      }
    } else {
      if (error) {
        setError(null);
        if (removeError) removeError();
      }
    }
  }, [disabled, param, type, error]);
  //#endregion

  //#region [methods]
  const getNewValue = () => {
    if (oldValue.current == inputRef.current.value) return oldValue.current;
    const value = inputRef.current.value;
    oldValue.current = value;
    const inputErr = getInputError(i18n, value, param);
    if (inputErr) {
      if (!error && addError) addError();
      setError(inputErr);
    } else {
      if (error) {
        setError(null);
        if (removeError) removeError();
      }
    }
    return value === '' || !value ? null : type === 'number' ? +value : value;
  };

  const handleInputBlur = async () => {
    if (!onBlur) return;
    const value = getNewValue();
    await onBlur(value);
  };

  const handleInputChange = async () => {
    if (!onChange) return;
    const value = getNewValue();
    await onChange(value);
  };
  //#endregion

  //#region [render]
  id = id ?? `input_${param.key}_` + new Date().getTime();
  const infoTooltipId = 'errTooltip_' + id;
  const errTooltipId = 'errTooltip_' + id;
  const inputClassName = error
    ? 'form-input-error'
    : !disabled &&
      !isNull(value) &&
      !isNull(param.default) &&
      value != param.default
    ? 'form-input-changed'
    : '';
  return (
    <div className={className}>
      {label && (
        <label className='form-input-label' htmlFor={id}>
          {label}
          {param.required ? '*' : ''}
          {showInfoIcon && (
            <>
              <FontAwesomeIcon
                icon={infoIcon ?? 'circle-question'}
                className='info-icon'
                onClick={onInfoClick}
                onMouseOver={() => setShowInfoTooltip(true)}
                onMouseOut={() => setShowInfoTooltip(false)}
                ref={iconRef}
              />
              {infoTooltip && (
                <Overlay
                  target={iconRef.current}
                  show={showInfoTooltip}
                  placement='right'
                >
                  {(props) => (
                    <Tooltip
                      id={infoTooltipId}
                      className='form-input-info-tooltip'
                      {...props}
                    >
                      {infoTooltip}
                    </Tooltip>
                  )}
                </Overlay>
              )}
            </>
          )}
        </label>
      )}
      <div className='form-input-body'>
        {topText && (
          <p className='form-input-small-text form-input-top-text'>{topText}</p>
        )}
        <InputGroup className='form-input'>
          <Form.Control
            id={id}
            className={inputClassName}
            type={type}
            disabled={disabled}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            onWheel={(e) => e.target.blur()}
            defaultValue={value !== null || value !== undefined ? value : ''}
            ref={inputRef}
            aria-describedby='form_input'
            data-tooltip-content={error}
            data-tooltip-id={errTooltipId}
            maxLength={param.maxLength}
          />
          {unit && param.unit && (
            <InputGroup.Text id='form_input' className='form-input-unit'>
              {i18n._(`unit.${param.unit}`)}
            </InputGroup.Text>
          )}
        </InputGroup>
        {bottomText && (
          <span className='form-input-small-text'>{bottomText}</span>
        )}
        {error &&
          createPortal(
            <ReactTooltip
              id={errTooltipId}
              place='bottom'
              className='error-tooltip'
              arrowColor='#f04460'
              opacity={1}
            />,
            document.body
          )}
      </div>
    </div>
  );
  //#endregion
};

export default FormInput;
