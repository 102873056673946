const {
  PIPE_NOMINAL_DIAMETER,
  PIPE_MATERIAL,
  UNIT
} = require('../../../../constants');
const { getDefaultValues } = require('../../../../utils/param.utils');

const getPipingParams = (isModular) => {
  return {
    material: {
      key: 'material',
      default: isModular ? PIPE_MATERIAL.STAINLESS_STEEL : PIPE_MATERIAL.STEEL,
      values: Object.values(PIPE_MATERIAL),
      translateValuesIds: Object.values(PIPE_MATERIAL).map(
        (mat) => `material.${mat}`
      ) // TODO côté front
    },
    // TODO en attente de la refonte de la page calculs : si la config est modulaire, distanceToCollector n'existe pas
    distanceToCollector: {
      key: 'distanceToCollector',
      default: 1.5,
      unit: UNIT.METER,
      required: true
    },
    hpaExchangerHeadLoss: {
      key: 'hpaExchangerHeadLoss',
      default: 2,
      unit: UNIT.WATER_COLUMN_METER,
      required: true
    }
  };
};

const getCollectorParams = (material, services, isModular) => {
  return {
    material: {
      key: 'material',
      default: isModular ? PIPE_MATERIAL.STAINLESS_STEEL : PIPE_MATERIAL.STEEL,
      values: Object.values(PIPE_MATERIAL),
      translateValuesIds: Object.values(PIPE_MATERIAL).map(
        (mat) => `material.${mat}`
      ) // TODO côté front
    },
    coldSideNd: {
      key: 'coldSideNd',
      default: isModular
        ? services.upperModules.evaporator.nominalDiameter
        : PIPE_NOMINAL_DIAMETER[material][0],
      values: PIPE_NOMINAL_DIAMETER[material]
    },
    heatSideNd: {
      key: 'heatSideNd',
      default: isModular
        ? services.upperModules.condensor.nominalDiameter
        : PIPE_NOMINAL_DIAMETER[material][0],
      values: PIPE_NOMINAL_DIAMETER[material]
    },
    // TODO en attente de la refonte de la page calculs : si la config est modulaire, distanceBetweenHps n'existe pas
    distanceBetweenHps: {
      key: 'distanceBetweenHps',
      default: 2,
      min: 0,
      unit: UNIT.METER,
      required: true
    },
    distanceToDestination: {
      key: 'distanceToDestination',
      default: 3,
      min: 0,
      unit: UNIT.METER,
      required: true
    }
  };
};

const DESTINATIONS = Object.freeze({
  exchangerHeadLoss: {
    key: 'exchangerHeadLoss',
    default: 2,
    unit: UNIT.WATER_COLUMN_METER,
    required: true
  },
  waterTankHeadLoss: {
    key: 'waterTankHeadLoss',
    default: 1,
    unit: UNIT.WATER_COLUMN_METER,
    required: true
  }
});

const SOURCES = Object.freeze({
  captureHeatExchangerHeadLoss: {
    key: 'captureHeatExchangerHeadLoss',
    default: 2,
    unit: UNIT.WATER_COLUMN_METER,
    required: true
  },
  injectionExchangerHeadLoss: {
    key: 'injectionExchangerHeadLoss',
    default: 2,
    unit: UNIT.WATER_COLUMN_METER,
    required: true
  }
});

const getDefaultHeatingPlantSettings = (services, isModular) => {
  return {
    piping: { ...getDefaultValues(getPipingParams(isModular)) },
    collectors: {
      ...getDefaultValues(
        getCollectorParams(PIPE_MATERIAL.STEEL, services, isModular)
      )
    },
    destinations: { ...getDefaultValues(DESTINATIONS) },
    sources: { ...getDefaultValues(SOURCES) }
  };
};

module.exports = {
  getPipingParams,
  getDefaultHeatingPlantSettings,
  DESTINATIONS,
  SOURCES,
  getCollectorParams
};
