const { MAX_DESCRIPTIONS } = require('../constants');
const {
  getCommonConstraints,
  getParamsConstraints,
  getSstParamsConstraints,
  getBoundsConstraints,
  GENERAL,
  GOAL,
  ITERATIONS,
  SIMU_GOAL,
  getSstBoundsConstraints
} = require('../models/design/calculationData.model');
const {
  isBtesLengthAbsent,
  isBtesProbeDepthAbsent,
  isBtesProbeDistanceAbsent,
  isT2final_BtesAbsent,
  isHPGHeatBtesPowerMaxAbsent,
  isHPGColdBtesPowerMaxAbsent,
  isHPAPowerMaxAbsent,
  isGasPowerMaxAbsent,
  isSolarThermalSurfaceAbsent,
  isPhotovoltaicAbsent,
  isQItesMaxAbsent,
  isHPGPowerMaxAbsent
} = require('../utils/calculationData.utils');

function isParamAbsent(key, project) {
  const constraints = getParamsConstraints();
  const sstConstraints = getSstParamsConstraints();
  const isAbsent = {
    [constraints.BtesLength.key]: isBtesLengthAbsent,
    [constraints.BtesProbeDepth.key]: isBtesProbeDepthAbsent,
    [constraints.BtesProbeDistance.key]: isBtesProbeDistanceAbsent,
    [constraints.T2final_Btes.key]: isT2final_BtesAbsent,
    [constraints.HPGHeatBtesPowerMax.key]: isHPGHeatBtesPowerMaxAbsent,
    [constraints.HPGColdBtesPowerMax.key]: isHPGColdBtesPowerMaxAbsent,
    [constraints.SolarThermalSurface.key]: isSolarThermalSurfaceAbsent,
    [constraints.KiloWattCretePV.key]: isPhotovoltaicAbsent,
    [constraints.QItesMax.key]: isQItesMaxAbsent,
    [sstConstraints.HPGPowerMaxPerSst.key]: isHPGPowerMaxAbsent,
    [sstConstraints.HPAPowerMaxPerSst.key]: isHPAPowerMaxAbsent,
    [sstConstraints.GasPowerMaxPerSst.key]: isGasPowerMaxAbsent
  };
  return isAbsent[key](project);
}

function getOptiSchema(years, selectedProjects) {
  const commonConstraints = getCommonConstraints(years);
  const boundsConstraints = getBoundsConstraints(selectedProjects);
  const sstBoundsConstraints = getSstBoundsConstraints();
  const schema = {
    type: 'object',
    properties: {
      resultId: {
        oneOf: [
          {
            type: 'string',
            pattern:
              '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}'
          },
          { type: 'null' }
        ]
      },
      constraints: {
        type: 'object',
        properties: {
          inp: {
            type: 'object',
            properties: {
              [commonConstraints.InitSimulationCalcul.key]: {
                type: commonConstraints.InitSimulationCalcul.type,
                const: false
              },
              [commonConstraints.InitYearsSimulations.key]: {
                type: commonConstraints.InitYearsSimulations.type,
                minimum: commonConstraints.InitYearsSimulations.min,
                maximum: commonConstraints.InitYearsSimulations.max
              },
              [commonConstraints.InitBalanceDuration.key]: {
                type: commonConstraints.InitBalanceDuration.type,
                minimum: commonConstraints.InitBalanceDuration.min,
                maximum: commonConstraints.InitBalanceDuration.max
              },
              [GENERAL.InitObjective.key]: { type: GENERAL.InitObjective.type },
              [GENERAL.InitReferenceSolution.key]: {
                type: GENERAL.InitReferenceSolution.type
              },
              [GOAL.InitConstraintCO2kg.key]: {
                type: GOAL.InitConstraintCO2kg.type,
                minimum: GOAL.InitConstraintCO2kg.min,
                maximum: GOAL.InitConstraintCO2kg.max
              },
              [GOAL.InitConstraintEnabled.key]: {
                type: GOAL.InitConstraintEnabled.type
              },
              [GOAL.InitConstraintEnergyFinale.key]: {
                type: GOAL.InitConstraintEnergyFinale.type,
                minimum: GOAL.InitConstraintEnergyFinale.min,
                maximum: GOAL.InitConstraintEnergyFinale.max
              },
              [GOAL.InitConstraintEnergyPrimary.key]: {
                type: GOAL.InitConstraintEnergyPrimary.type,
                minimum: GOAL.InitConstraintEnergyPrimary.min,
                maximum: GOAL.InitConstraintEnergyPrimary.max
              },
              [GOAL.InitConstraintENR.key]: {
                type: GOAL.InitConstraintENR.type,
                minimum: GOAL.InitConstraintENR.min,
                maximum: GOAL.InitConstraintENR.max
              },
              [GOAL.InitConstraintHpg.key]: {
                type: GOAL.InitConstraintHpg.type,
                minimum: GOAL.InitConstraintHpg.min,
                maximum: GOAL.InitConstraintHpg.max
              },
              [GOAL.InitConstraintGasPart.key]: {
                type: GOAL.InitConstraintGasPart.type,
                minimum: GOAL.InitConstraintGasPart.min,
                maximum: GOAL.InitConstraintGasPart.max
              },
              [GOAL.InitConstraintNbOptim.key]: {
                type: GOAL.InitConstraintNbOptim.type,
                minimum: GOAL.InitConstraintNbOptim.min,
                maximum: GOAL.InitConstraintNbOptim.max
              },
              [GOAL.InitConstraintReference.key]: {
                type: GOAL.InitConstraintReference.type,
                minimum: GOAL.InitConstraintReference.min
              },
              [ITERATIONS.FuncEvaluations.key]: {
                type: ITERATIONS.FuncEvaluations.type,
                minimum: ITERATIONS.FuncEvaluations.min
              }
            },
            allOf: [
              {
                if: {
                  properties: {
                    [GOAL.InitConstraintEnabled.key]: { const: false }
                  }
                },
                then: {
                  properties: {
                    [GOAL.InitConstraintCO2kg.key]: {
                      const: GOAL.InitConstraintCO2kg.default
                    },
                    [GOAL.InitConstraintEnergyFinale.key]: {
                      const: GOAL.InitConstraintEnergyFinale.default
                    },
                    [GOAL.InitConstraintEnergyPrimary.key]: {
                      const: GOAL.InitConstraintEnergyPrimary.default
                    },
                    [GOAL.InitConstraintENR.key]: {
                      const: GOAL.InitConstraintENR.default
                    },
                    [GOAL.InitConstraintHpg.key]: {
                      const: GOAL.InitConstraintHpg.default
                    },
                    [GOAL.InitConstraintGasPart.key]: {
                      const: GOAL.InitConstraintGasPart.default
                    },
                    [GOAL.InitConstraintNbOptim.key]: {
                      const: GOAL.InitConstraintNbOptim.default
                    },
                    [GOAL.InitConstraintReference.key]: {
                      const: GOAL.InitConstraintReference.default
                    }
                  }
                }
              }
            ],
            required: [
              commonConstraints.InitSimulationCalcul.key,
              commonConstraints.InitYearsSimulations.key,
              commonConstraints.InitBalanceDuration.key,
              GENERAL.InitObjective.key,
              GENERAL.InitReferenceSolution.key,
              GOAL.InitConstraintEnabled.key,
              GOAL.InitConstraintNbOptim.key,
              ITERATIONS.FuncEvaluations.key
            ],
            additionalProperties: false
          },
          bounds: {
            type: 'array',
            minItems: 2,
            maxItems: 2,
            areBounds: true, // mot clé défini dans ajv.conf.js
            items: {
              type: 'object',
              properties: {},
              required: [],
              additionalProperties: false
            }
          }
        },
        required: ['inp', 'bounds'],
        additionalProperties: false
      },
      path: {
        type: 'string'
      },
      selectedDescriptionsIndexes: {
        type: 'array',
        items: {
          type: 'number',
          minimum: 0,
          maximum: MAX_DESCRIPTIONS - 1
        },
        minItems: 1,
        maxItems: MAX_DESCRIPTIONS
      }
    },
    required: ['constraints', 'path', 'selectedDescriptionsIndexes'],
    additionalProperties: false
  };

  Object.values(boundsConstraints).forEach((constraint) => {
    if (
      !selectedProjects.every((project) =>
        isParamAbsent(constraint.key, project)
      )
    ) {
      schema.properties.constraints.properties.bounds.items.properties[
        constraint.key
      ] = {
        type: constraint.type,
        minimum: constraint.min,
        maximum: constraint.max,
        exclusiveMinimum: constraint.exclusiveMin,
        exclusiveMaximum: constraint.exclusiveMax
      };
      schema.properties.constraints.properties.bounds.items.required.push(
        constraint.key
      );
    }
  });

  Object.values(sstBoundsConstraints).forEach((constraint) => {
    if (
      !selectedProjects.every((project) =>
        isParamAbsent(constraint.key, project)
      )
    ) {
      // chaque contrainte qui dépend des sous-stations est un tableau où le max de chaque item est dynamique.
      // or, les schémas JSON ne prennent pas en charge les maximums dynamiques par défaut, on ne définit donc pas de maximum.
      schema.properties.constraints.properties.bounds.items.properties[
        constraint.key
      ] = {
        type: 'array',
        items: {
          type: constraint.type,
          minimum: constraint.min,
          exclusiveMinimum: constraint.exclusiveMin
        }
      };
      schema.properties.constraints.properties.bounds.items.required.push(
        constraint.key
      );
    }
  });

  return schema;
}

function getSimuSchema(years, selectedProject) {
  const commonConstraints = getCommonConstraints(years);
  const paramsConstraints = getParamsConstraints(selectedProject);
  const sstParamsConstraints = getSstParamsConstraints();
  const schema = {
    type: 'object',
    properties: {
      resultId: {
        oneOf: [
          {
            type: 'string',
            pattern:
              '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}'
          },
          { type: 'null' }
        ]
      },
      constraints: {
        type: 'object',
        properties: {
          inp: {
            type: 'object',
            properties: {
              [commonConstraints.InitSimulationCalcul.key]: {
                type: commonConstraints.InitSimulationCalcul.type,
                const: true
              },
              [commonConstraints.InitYearsSimulations.key]: {
                type: commonConstraints.InitYearsSimulations.type,
                minimum: commonConstraints.InitYearsSimulations.min,
                maximum: commonConstraints.InitYearsSimulations.max
              },
              [commonConstraints.InitBalanceDuration.key]: {
                type: commonConstraints.InitBalanceDuration.type,
                minimum: commonConstraints.InitBalanceDuration.min,
                maximum: commonConstraints.InitBalanceDuration.max
              },
              [ITERATIONS.FuncEvaluations.key]: {
                type: ITERATIONS.FuncEvaluations.type,
                const: 0
              },
              [SIMU_GOAL.InitConstraintHpg.key]: {
                type: SIMU_GOAL.InitConstraintHpg.type,
                minimum: SIMU_GOAL.InitConstraintHpg.min,
                maximum: SIMU_GOAL.InitConstraintHpg.max
              },
              [SIMU_GOAL.LbHpgPartTargetEnabled.key]: {
                type: SIMU_GOAL.LbHpgPartTargetEnabled.type
              }
            },
            allOf: [
              {
                if: {
                  properties: {
                    [SIMU_GOAL.LbHpgPartTargetEnabled.key]: { const: false }
                  }
                },
                then: {
                  properties: {
                    [SIMU_GOAL.InitConstraintHpg.key]: {
                      const: SIMU_GOAL.InitConstraintHpg.default
                    }
                  }
                }
              }
            ],
            required: [
              commonConstraints.InitSimulationCalcul.key,
              commonConstraints.InitYearsSimulations.key,
              commonConstraints.InitBalanceDuration.key,
              ITERATIONS.FuncEvaluations.key,
              SIMU_GOAL.InitConstraintHpg.key,
              SIMU_GOAL.LbHpgPartTargetEnabled.key
            ],
            additionalProperties: false
          },
          params: {
            type: 'object',
            properties: {},
            required: [],
            additionalProperties: false
          }
        },
        required: ['inp', 'params'],
        additionalProperties: false
      },
      path: {
        type: 'string'
      },
      selectedDescriptionsIndexes: {
        type: 'array',
        items: {
          type: 'number',
          minimum: 0,
          maximum: MAX_DESCRIPTIONS - 1
        },
        minItems: 1,
        maxItems: 1
      }
    },
    required: ['constraints', 'path', 'selectedDescriptionsIndexes'],
    additionalProperties: false
  };

  Object.values(paramsConstraints).forEach((constraint) => {
    if (!isParamAbsent(constraint.key, selectedProject)) {
      if (constraint.values) {
        schema.properties.constraints.properties.params.properties[
          constraint.key
        ] = {
          type: constraint.type,
          enum: constraint.values
        };
      } else {
        schema.properties.constraints.properties.params.properties[
          constraint.key
        ] = {
          type: constraint.type,
          minimum: constraint.min,
          maximum: constraint.max,
          exclusiveMinimum: constraint.exclusiveMin,
          exclusiveMaximum: constraint.exclusiveMax
        };
      }
      schema.properties.constraints.properties.params.required.push(
        constraint.key
      );
    }
  });

  Object.values(sstParamsConstraints).forEach((constraint) => {
    if (!isParamAbsent(constraint.key, selectedProject)) {
      // chaque contrainte qui dépend des sous-stations est un tableau où le max de chaque item est dynamique.
      // or, les schémas JSON ne prennent pas en charge les maximums dynamiques par défaut, on ne définit donc pas de maximum.
      schema.properties.constraints.properties.params.properties[
        constraint.key
      ] = {
        type: 'array',
        items: {
          type: constraint.type,
          minimum: constraint.min,
          exclusiveMinimum: constraint.exclusiveMin
        }
      };
      schema.properties.constraints.properties.params.required.push(
        constraint.key
      );
    }
  });
  return schema;
}

module.exports = { getOptiSchema, getSimuSchema };
