import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { MODULE_TYPE } from '../../../../../../../../../server/constants';
import {
  getPrimaryModuleParams,
  getSecondaryModuleParams
} from '../../../../../../../../../server/models/config/buildingDescription/services/hydraulic.model';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import { getSortedNeeds } from '../../../../../../../utils/config.utils';
import './HydraulicEquipmentTable.css';

const HydraulicEquipmentTable = ({
  data,
  moduleType,
  className,
  onFormChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleEquipmentChange = (evt, need) => {
    const { value, checked } = evt.target;
    const { hydraulicEquipment } = config.ConfigsSst[0].Data.services[need];
    hydraulicEquipment[moduleType][value] = checked;
    onFormChange(config);
  };
  //#endregion

  //#region [memos]
  const moduleParams = useMemo(() => {
    const { services } = config.ConfigsSst[0].Data;
    return moduleType === MODULE_TYPE.PRIMARY
      ? getPrimaryModuleParams(services)
      : getSecondaryModuleParams(services);
  }, []);
  //#endregion

  //#region [methods]
  const checkForEquipmentWarning = (colName, need, checked) => {
    return (
      (moduleParams[colName][need].default && !checked) ||
      (!moduleParams[colName][need].default && checked)
    );
  };
  //#endregion

  //#region [render]
  const { services } = config.ConfigsSst[0].Data;
  const sortedNeeds = getSortedNeeds(services.needs);
  return (
    <div className={className}>
      <table className='custom-table hydraulic-equipment-table'>
        <thead>
          <tr>
            <th></th>
            {data.map(([colName, _]) => (
              <th key={'hydraulic_header_' + colName}>
                {i18n._(`hydraulicConfig.table.th.${colName}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedNeeds
            .filter((need) => !!services[need].hydraulicEquipment)
            .map((need) => {
              const { hydraulicEquipment } = services[need];
              return (
                <tr key={'hydraulic_tr_' + need}>
                  <td>{i18n._(`need.${need}`)}</td>
                  {data.map(([colName, equipment], index) => {
                    return (
                      <td key={'hydraulic_td_' + need + '_' + index}>
                        {equipment[need] &&
                          (() => {
                            const checked =
                              hydraulicEquipment[moduleType][equipment[need]];
                            const warning = checkForEquipmentWarning(
                              colName,
                              need,
                              checked
                            );
                            const checkboxId = 'settings_he_' + index + colName;
                            return (
                              <div
                                data-tooltip-content={
                                  warning
                                    ? checked
                                      ? i18n._('equipment.notChecked.warning')
                                      : i18n._('equipment.checked.warning')
                                    : null
                                }
                                data-tooltip-id={'error_' + checkboxId}
                                style={{ width: 'fit-content' }}
                              >
                                <Form.Check
                                  type='checkbox'
                                  label={equipment[need]}
                                  onChange={(evt) =>
                                    handleEquipmentChange(evt, need)
                                  }
                                  value={equipment[need]}
                                  checked={checked}
                                  className={warning ? 'warning' : ''}
                                  id={'equipment_' + equipment[need]}
                                  disabled={config.IsModular}
                                />
                                {warning &&
                                  createPortal(
                                    <Tooltip
                                      id={'error_' + checkboxId}
                                      place='bottom'
                                      className='error-tooltip'
                                      arrowColor='#f04460'
                                      opacity={1}
                                    />,
                                    document.body
                                  )}
                              </div>
                            );
                          })()}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
  //#endregion
};

export default HydraulicEquipmentTable;
