import GeneralPage from './buildingDescription/general/GeneralPage';
import ServicesHydraulicPage from './buildingDescription/services/hydraulic/HydraulicPage';
import ServicesSettingsPage from './buildingDescription/services/settings/SettingsPage';
import EquipmentListPage from './deliverable/equipmentList/EquipmentListPage';
import PumpsHydraulicCalculationsPage from './hydraulicEquipment/pumps/calculations/HydraulicCalculationsPage';
import HeatingPlantPage from './hydraulicEquipment/pumps/heatingPlant/HeatingPlantPage';
import RegimesPage from './hydraulicEquipment/pumps/regimes/RegimesPage';
import GeostorageHydraulicCalculationsPage from './thermalProduction/geostorage/calculations/HydraulicCalculationsPage';
import GeostorageHydraulicPage from './thermalProduction/geostorage/hydraulic/HydraulicPage';
import GeostorageSettingsPage from './thermalProduction/geostorage/settings/SettingsPage';
import HpsHydraulicPage from './thermalProduction/heatpumps/hydraulic/HydraulicPage';
import HpsSelectionPage from './thermalProduction/heatpumps/selection/HpsSelectionPage';
import HpsSettingsPage from './thermalProduction/heatpumps/settings/SettingsPage';

const pages = [
  {
    id: 'general',
    component: GeneralPage
  },
  {
    id: 'services/settings',
    component: ServicesSettingsPage
  },
  {
    id: 'services/hydraulic',
    component: ServicesHydraulicPage
  },
  {
    id: 'heatpumps/settings',
    component: HpsSettingsPage
  },
  {
    id: 'heatpumps/selection',
    component: HpsSelectionPage
  },
  {
    id: 'heatpumps/hydraulic',
    component: HpsHydraulicPage
  },
  {
    id: 'geostorage/settings',
    component: GeostorageSettingsPage
  },
  {
    id: 'geostorage/hydraulic',
    component: GeostorageHydraulicPage
  },
  {
    id: 'geostorage/calculations',
    component: GeostorageHydraulicCalculationsPage
  },
  {
    id: 'pumps/regimes',
    component: RegimesPage
  },
  {
    id: 'pumps/heatingPlant',
    component: HeatingPlantPage
  },
  {
    id: 'pumps/calculations',
    component: PumpsHydraulicCalculationsPage
  },
  {
    id: 'equipmentList',
    component: EquipmentListPage
  }
];
export default pages;
