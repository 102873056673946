import { useLingui } from '@lingui/react';
import React from 'react';
import Bloc from '../../../../components/Bloc/Bloc';
import ConfigFormPage from '../../components/ConfigFormPage/ConfigFormPage';
import EquipmentListSection from './sections/equipmentListSection/EquipmentListSection';
import HeatingPlantPriceSection from './sections/heatingPlantPriceSection/HeatingPlantPriceSection';

const EquipmentListPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <ConfigFormPage>
      <Bloc title={i18n._('config.equipmentList.title')}>
        <HeatingPlantPriceSection />
        <EquipmentListSection />
      </Bloc>
    </ConfigFormPage>
  );
  //endregion
};
export default EquipmentListPage;
