import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import Section from '../../../../../../components/Section/Section';
import TwoColsTemplateTable from '../../../../../../components/TemplateTables/TwoColsTemplateTable/TwoColsTemplateTable';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import './HeatingPlantPriceSection.css';
import heatingPlantPriceTemplate from './templates';

const HeatingPlantPriceSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [memos]
  const HPPTemplates = useMemo(() => {
    return heatingPlantPriceTemplate(i18n, config.ConfigsSst[0]);
  }, [config]);
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('config.equipmentList.heatingPlantPrice.title')}
      level={2}
      open
      className='heating-plant-price-section'
    >
      <TwoColsTemplateTable template={HPPTemplates} />
    </Section>
  );
  //endregion
};
export default HeatingPlantPriceSection;
