// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simu-sst-table {
  table-layout: fixed;
}

.simu-sst-table thead tr th {
  background-color: #f9fafb;
  color: #424a56;
  border-bottom: 2px solid #e7e7ea;
  text-align: left;
}

.simu-sst-table thead tr th:not(:first-of-type) {
  border-left: 1px solid #ceced2;
}

.simu-sst-table thead tr th:first-of-type,
.simu-sst-table tbody tr td:first-of-type {
  min-width: 200px;
  word-break: break-word;
}

.simu-sst-table tbody tr .col-12 {
  padding: 0;
}

.simu-sst-table tbody tr td:first-of-type {
  font-weight: 500;
}

.simu-sst-table tbody tr td:first-of-type svg {
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/simulation/sections/SimuSizingSection/components/SimuSstTable/SimuSstTable.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;;EAEE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".simu-sst-table {\n  table-layout: fixed;\n}\n\n.simu-sst-table thead tr th {\n  background-color: #f9fafb;\n  color: #424a56;\n  border-bottom: 2px solid #e7e7ea;\n  text-align: left;\n}\n\n.simu-sst-table thead tr th:not(:first-of-type) {\n  border-left: 1px solid #ceced2;\n}\n\n.simu-sst-table thead tr th:first-of-type,\n.simu-sst-table tbody tr td:first-of-type {\n  min-width: 200px;\n  word-break: break-word;\n}\n\n.simu-sst-table tbody tr .col-12 {\n  padding: 0;\n}\n\n.simu-sst-table tbody tr td:first-of-type {\n  font-weight: 500;\n}\n\n.simu-sst-table tbody tr td:first-of-type svg {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
